@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.S-sec1 {
  padding: 8% 8% 4% 8%;
  width: 100%;
  height: auto;
}

.S-sec1-sp1 {
  background-color: #efefef;
  padding: 0.3% ;
  border-radius: 4px;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
  font-size: 0.68vw;
  font-weight: 500;
}

.S-sec1 h1 {
  margin-top: 1%;
  font-weight: 400;
  font-family: "Barlow";
  font-size: 4.25vw;
  letter-spacing: -1.2px;
}

.S-sec1-sp2 {
  color: #707070;
}

@media only screen and (max-width: 768px) {
  .S-sec1 {
    padding: 10% 2% 12% 7%;
    width: 100%;
    height: auto;
  }

  .S-sec1-sp1 {
    background-color: #efefef;
    padding: 0.6% 0.8%;
    border-radius: 2px;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
    font-size: 1.4vw;
  }

  .S-sec1 h1 {
    font-size: 5.4vw;
    margin-top: 3%;
    font-weight: 400;
    letter-spacing: -1.2px;
    padding-right: 4%;
  }

  .S-sec1 h1 br {
    display: none;
  }
  .S-sec1-sp2 {
    margin-top: 1%;
    color: #707070;
    font-family: "Barlow";
    font-weight: 400;
    letter-spacing: -1.2px;
    font-size: 5.2vw;
  }
}

@media only screen and (max-width: 360px) {
  .S-sec1 {
    padding: 30% 5% 8% 4%;
    width: 100%;
    height: auto;
  }

  .S-sec1-sp1 {
    background-color: #efefef;
    padding: 1% 1.5%;
    font-size: 2.8vw;
    font-weight: 500;
    border-radius: 3px;
  }

  .S-sec1 h1 {
    font-size: 8vw;
    margin-top: 10%;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: "Barlow";
    letter-spacing: -1.2px;
  }

  .S-sec1 h1 br {
    display: none;
  }
  .S-sec1-sp2 {
    margin-top: 1%;
    color: #707070;
    font-family: "Barlow";
    font-weight: 400;
    letter-spacing: -1.2px;
    font-size: 8vw;
  }
}

/* section 2 */

.S-sec2 {
  height: auto;
  width: 100%;
  padding: 1.2% 2% 1% 1.2%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  border-bottom: 1px solid rgb(229, 226, 226);
  transition: 1.5s all ease;
}

.S-sec2-d1 img {
  width: 32vw;
  height: 24vw;
}

.S-sec2-d2 {
  height: 24.5vw;
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: 1fr 0.23fr;
}

.S-sec2-d2-in1 h1 {
  font-size: 2.6vw;
  font-family: "Barlow";
  letter-spacing: -1.2px;
  color: #161616;
  font-weight: 400;
  margin-top: 1%;
  margin-bottom: 2.5%;
}
.S-sec2-d2-in1 p {
  font-size: 1vw;
  color: #9c9c9c;
  margin-bottom: 4%;
  font-family: "Barlow";
  padding-right: 18%;
}

.S-sec2-d2-in1 h6 {
  font-size: 1vw;
  margin-top: 3%;
  margin-bottom: 4%;
  font-weight: 500;
}

.S-sec2-d2-in1-sp1 {
  background-color: #f2f2f2;
  padding: 1.5%;
  font-size: 0.9vw;
  margin-right: 1%;
  border-radius: 3px;
  margin-bottom: 5%;
  font-weight: 500;
}
.S-sec2-d2-in1-sp1 img {
  margin-right: 1%;
  width: 0.7vw;
  height: 0.8vw;
}

.S-sec2-d2-in1-sp2 {
  background-color: #f2f2f2;
  padding: 1.5%;
  display: inline-block;
  font-size: 0.9vw;
  margin-top: 2.9%;
  border-radius: 3px;
  font-weight: 500;
}

.S-sec2-d2-in1-sp2 img {
  margin-right: 5px;
  width: 0.7vw;
  height: 0.8vw;
}

.S-sec2-d2-in2 {
  display: flex;
  align-items: flex-end;
  padding-bottom: 50%;
}

.S-sec2-btn {
  display: inline-block;
  font-size: 0.9vw;
  padding: 5% 8%;
  color: #ffffff;
  background-color: black;
  border: none;
  border-radius: 3px;
  text-decoration: none;
  font-family: "Space Grotesk";
  font-weight: 500;
  cursor: pointer;
}

.S-sec2-btn-sp-d {
  color: #9c9c9c;
  padding-left: 6px;  
  padding-right: 5px;
  background: none;
}

.S-sec2-AR {
  height: .85vw;
  width: 1.2vw;
  position: relative;
  top: -0.5px;
}

/* hover effect */

.S-sec2:hover {
  background-color: #161616;
}

.S-sec2:hover h1 {
  color: #ffffff;
}
.S-sec2:hover p {
  color: #9c9c9c;
}

.S-sec2:hover h6 {
  color: #ffffff;
}

.S-sec2:hover span {
  background-color: #222222;
  color: #ffffff;
}

.S-sec2:hover .S-sec2-btn {
  background-color: #0f46f5;
}

.S-sec2:hover .S-sec2-btn-sp-d {
  background-color: #0f46f5;
  color: #9c9c9c;
}

@media only screen and (max-width: 768px) {
  .S-sec2 {
    height: auto;
    width: 100%;
    padding: 2% 4% 1.5% 1.2%;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
  }

  .S-sec2-d1 img {
    width: 38vw;
    height: 29vw;
  }

  .S-sec2-d2 {
    height: 30vw;
    padding-left: 3%;
    display: flex;
    flex-direction: column;
  }

  .S-sec2-d2-in1 h1 {
    font-size: 3.6vw;
    font-weight: 400;
    margin-top: 3%;
    margin-bottom: 4%;
  }
  .S-sec2-d2-in1 p {
    font-size: 1.8vw;
    padding-right: 1%;
    color: #9c9c9c;
    margin-bottom: 1%;
    line-height: 20px;
    font-family: "Barlow";
  }

  .S-sec2-d2-in1 h6 {
    display: none;
    font-size: 1.2vw;
    margin-bottom: 5%;
    font-weight: 500;
  }

  .S-sec2-d2-in1-sp1 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
    font-size: 1vw;
    margin-right: 1%;
    border-radius: 3px;
    margin-bottom: 5%;
  }
  .S-sec2-d2-in1-sp1 img {
    display: none;
    margin-right: 1%;
    width: 0.8vw;
    height: 0.9vw;
  }

  .S-sec2-d2-in1-sp2 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
    /* display: inline-block; */
    font-size: 1vw;
    margin-top: 2.9%;
    border-radius: 3px;
  }

  .S-sec2-d2-in1-sp2 img {
    display: none;
    margin-right: 5px;
    width: 0.8vw;
    height: 0.9vw;
  }
  .S-sec2-btn {
    display: inline-block;
    font-size: 1.9vw;
    padding: 3% 1% 3% 2%;
    color: #ffffff;
    background-color: black;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    font-family: "Space Grotesk";
    font-weight: 500;
    width: 14vw;
    margin-left: 70%;
  }

  .S-sec2-btn-sp-d {
    color: #9c9c9c;
    padding-left: 5px;
    padding-right: 5px;
    background: none;
    display: none;
  }

  .S-sec2-AR {
    height: 1.8vw;
    width: 2.5vw;
    position: relative;
    top: 3px;
    display: none;
  }

  /* buttons */

  .tbv-bt-6 {
    padding-top: 12%;
  }

  .tbv-bt-7 {
    padding-top: 8%;
  }

  .tbv-bt-8-p {
    margin-bottom: 1%;
  }

  .tbv-bt-8 {
    margin-bottom: 2%;
  }

  .tbv-bt-9 {
    padding-top: 7%;
  }

  .tbv-bt-10 {
    padding-top: 7%;
  }

  .tbv-bt-11 {
    padding-top: 7%;
  }

  /* domains btns  */

  .d-tbv-bt-3 {
    padding-top: 3%;
  }

  .d-tbv-bt-4 {
    padding-top: 12%;
  }

  .d-tbv-bt-5 {
    padding-top: 8%;
  }

  .d-tbv-bt-6 {
    padding-top: 3%;
  }

  .d-tbv-bt-7 {
    padding-top: 4%;
  }

  .d-tbv-bt-8 {
    padding-top: 9%;
  }

  .d-tbv-bt-9 {
    padding-top: 4%;
  }
  .d-tbv-bt-10 {
    padding-top: 14%;
  }
}

@media only screen and (max-width: 360px) {
  .S-sec2 {
    height: auto;
    width: 100%;
    padding: 4%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .S-sec2-d1 img {
    width: 100%;
    height: auto;
  }

  .S-sec2-d2 {
    height: auto;
    width: 100%;
    display: block;
    text-align: start;
    padding-left: 0%;
  }

  .S-sec2-d2-in1 h1 {
    font-size: 7vw;
    font-weight: 400;
    margin-top: 7%;
    margin-bottom: 5%;
    font-family: "Barlow";
    letter-spacing: -0.96px;
  }
  .S-sec2-d2-in1 p {
    font-size: 3.8vw;
    padding-right: 0%;
    color: #9c9c9c;
    margin-bottom: 3%;
    line-height: 20px;
    font-weight: 400;
  }

  .S-sec2-d2-in1 h6 {
    display: none;
    font-size: 1.2vw;
    margin-bottom: 5%;
    font-weight: 500;
  }

  .S-sec2-d2-in1-sp1 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
    font-size: 1vw;
    margin-right: 1%;
    border-radius: 3px;
    margin-bottom: 5%;
  }
  .S-sec2-d2-in1-sp1 img {
    display: none;
    margin-right: 1%;
    width: 0.8vw;
    height: 0.9vw;
  }

  .S-sec2-d2-in1-sp2 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
    /* display: inline-block; */
    font-size: 1vw;
    margin-top: 2.9%;
    border-radius: 3px;
  }

  .S-sec2-d2-in1-sp2 img {
    display: none;
    margin-right: 5px;
    width: 0.8vw;
    height: 0.9vw;
  }
  .S-sec2-btn {
    display: inline-block;
    font-size: 4vw;
    padding: 4% 2% 4% 4%;
    color: #ffffff;
    background-color: black;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    font-family: "Space Grotesk";
    font-weight: 500;
    width: 40vw;
    margin-left: 0%;
  }

  .S-sec2-btn-sp-d {
    color: #9c9c9c;
    padding-left: 7px;
    padding-right: 7px;
    background: none;
  }

  .S-sec2-AR {
    height: 4vw;
    width: 5.5vw;
    position: relative;
    top: 3px;
  }
}
