@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");


.P-sec1{
    margin-top: 4%;
    padding: 6%;
}

.P-sec1 span{
    color: black;
    border: 1px solid white;
    background: #EFEFEF;
    font-size: 0.7vw;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 4px;
    font-family: "space Grotesk";
    border-radius: 2px;
}

.P-sec1 h1{
    color: rgb(19, 19, 19);
    padding-top: 2%;
    font-family: "barlow";
    letter-spacing: 0.5px;
    font-size: 4vw;
    font-weight: 400;
}


.p-sec2-d1 img {
    width: 32vw;
    height: 30vw;
  }

  .p-sec2-d2 {
    height: 24.5vw;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2.5%;
    
  }

  .p-sec2-d2-in1 h1 {
    font-size: 2.7vw;
    font-family: "Barlow";
    letter-spacing: -1.2px;
    color: #161616;
    font-weight: 400;
    margin-top: 1%;
    margin-bottom: 2.5%;
  }

  .p-sec2-d2-in1 p {
    font-size: 1.3vw;
    color: #9c9c9c;
    margin-bottom: 2%;
    font-family: "Barlow";
    padding-right: 8%;
  }