@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.A-sec1 {
  padding: 10% 10% 4% 8%;
}

.A-sec1 span {
  background-color: #efefef;
  font-size: 0.7vw;
  font-family: "Space Grotesk";
  padding: 0.5%;
  border-radius: 3px;
  font-weight: 500;
  letter-spacing: 1px;
}

.A-sec1 h1 {
  font-size: 4.1vw;
  font-weight: 400;
  letter-spacing: -1.2px;
  margin-top: 2%;
  font-family: "Barlow";
  line-height: 60px;
}
.a-sec1h1-t {
  display: none;
}

@media only screen and (max-width: 768px) {
  .A-sec1 {
    padding: 17% 20% 2% 5%;
  }

  .A-sec1 span {
    background-color: #efefef;
    padding: 0.8%;
    border-radius: 3px;
    font-size: 1.5vw;
  }

  .A-sec1 br {
    display: none;
  }

  .A-sec1 h1 {
    font-size: 6.8vw;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 4% 0;
    font-family: "Barlow";
  }

  .a-sec1h1-t {
    display: block;
  }
}

@media only screen and (max-width: 360px) {
  .A-sec1 {
    padding: 30% 2% 2% 5%;
  }

  .A-sec1 span {
    background-color: #efefef;
    padding: 1%;
    border-radius: 3px;
    font-size: 4vw;
  }

  .A-sec1 br {
    display: none;
  }

  .A-sec1 h1 {
    font-family: "Barlow";
    font-size: 8.5vw;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 45px;
    margin: 10% 0;
  }
}

/* section 2 */

.A-sec2 {
  width: 100%;
  height: 100vh;
  padding: 3% 0% 0% 0%;
}

.a-sec2-img {
  width: 100%;
  height: auto;
}

.a-sec2-mbv-img {
  display: none;
}

@media only screen and (max-width: 768px) {
  .A-sec2 {
    width: 100%;
    height: auto;
    padding: 3% 0% 8% 0%;
  }

  .a-sec2-img {
    width: 100%;
    height: 60vh;
  }

  .a-sec2-mbv-img {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .A-sec2 {
    width: 100%;
    height: fit-content;
    padding: 3% 0% 8% 0%;
  }

  .a-sec2-img {
    width: 100%;
    height: fit-content;
    display: none;
  }

  .a-sec2-mbv-img {
    display: block;
    width: 100%;
    height: fit-content;
  }
}

/* section 3 */

.A-sec3-c {
  height: auto;
  width: 100%;
  padding: 0 7%;
}

.A-sec3-one {
  height: auto;
  width: 100%;
  grid-column: 1/3;
}

.A-sec3-one h1 {
  font-size: 3.9vw;
  font-weight: 400;
  margin-bottom: 1.8%;
  font-family: "Barlow";
  letter-spacing: -0.5px;
}
.A-sec3-one h1 span {
  color: #707070;
}

.A-sec3-two {
  margin-top: 5%;
  margin-left: 35%;
  margin-right: 5%;
  padding-bottom: 15%;
}

.a-sec3-c-p1 {
  margin-top: 1%;
  font-size: 1.55vw;
  letter-spacing: 1px;
  padding-right: 4%;
  margin-bottom: 4%;
  font-family: "Barlow";
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 28px;
  color: #161616;
}

.A-sec3-two h6 {
  font-size: 1.25vw;
  margin: 7% 0 4% 0%;
  font-weight: 600;
  font-family: "Barlow";
  letter-spacing: -1px;
  margin-bottom: 5%;
}
hr {
  margin: 1% 0;
}

.A-sec3-two h4 {
  font-size: 2vw;
  margin-right: 1%;
  font-weight: 400;
  font-family: "Barlow";
  margin-bottom: 2%;
  cursor: pointer;
}

.A-sec3-two span {
  font-family: "Barlow";
  margin-top: 2%;
  font-size: 2.9vw;
  font-weight: 400;
  margin-bottom: 0%;
  margin-right: 1.8%;
}

.A-sec3-h-p1 {
  margin-left: 5%;
  margin-bottom: 4%;
  /* display: none; */
  font-family: "Barlow";
  font-size: 1.5vw;
  letter-spacing: -0.1px;
  line-height: 30px;
}

/* .A-sec3-two h4:hover + .A-sec3-h-p1 {
  display: block;
} */

/* section 3 tablet version */

@media only screen and (max-width: 768px) {
  .A-sec3-c {
    height: auto;
    width: 100%;
    padding: 0 0% 10% 0%;
  }

  .A-sec3-one {
    height: auto;
    width: 100%;
    grid-column: 1/3;
  }

  .A-sec3-one h1 {
    font-size: 5vw;
    font-weight: 400;
    padding-left: 5%;
    margin-bottom: 4%;
  }

  .A-sec3-two {
    margin-top: 6%;
    margin-left: 0%;
    margin-right: 0%;
    padding-bottom: 5%;
  }

  .a-sec3-c-p1 {
    margin-top: 1%;
    font-size: 2.7vw;
    line-height: 29px;
    letter-spacing: 1px;
    padding-right: 4%;
    padding-left: 5%;
    margin-bottom: 5%;
    font-family: "Barlow";
  }

  .A-sec3-two h6 {
    font-size: 2vw;
    margin: 9% 0 5% 0%;
    font-weight: 600;
    padding-left: 5%;
  }
  hr {
    margin: 4% 0;
  }

  .A-sec3-two h4 {
    font-size: 2.8vw;
    margin-right: 1%;
    font-weight: 400;
    margin-top: 2%;
    padding-left: 5%;
 
  }

  .A-sec3-two span {
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 0%;
    margin-right: 3%;
  }

  .A-sec3-h-p1 {
    font-size: 2vw;
    margin-left: 10%;
    padding-right: 3%;
    margin-bottom: 2%;
    display: none;
    line-height: 20px;
  }

  .A-sec3-two h4:hover + .A-sec3-h-p1 {
    display: block;
  }
}

/* section 3 mobile version */

@media only screen and (max-width: 360px) {
  .A-sec3-c {
    height: auto;
    width: 100%;
    padding: 5% 3% 30% 1%;
  }

  .A-sec3-one {
    height: auto;
    width: 100%;
    grid-column: 1/3;
  }

  .A-sec3-one h1 {
    font-size: 10vw;
    font-weight: 400;
    margin-bottom: 5%;
  }

  hr {
    font-weight: 700;
  }

  .A-sec3-two {
    margin-top: 10%;
    margin-left: 0%;
    margin-right: 0%;
    padding-bottom: 5%;
  }

  .a-sec3-c-p1 {
    margin-top: 1%;
    font-size: 4vw;
    color: #161616;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 1px;
    padding-right: 0%;
    margin-bottom: 9%;
  }

  .A-sec3-two h6 {
    font-size: 3.5vw;
    margin: 10% 0 10% 0%;
    font-weight: 600;
  }
  hr {
    margin: 6% 0;
  }

  .A-sec3-two h4 {
    font-size: 5vw;
    margin-right: 1%;
    margin-bottom: 5%;
    font-weight: 400;
    margin-top: 5%;
  }

  .A-sec3-two span {
    font-size: 8vw;
    font-weight: 300;
    margin-bottom: 0%;
    margin-right: 3%;
  }

  .A-sec3-h-p1 {
    font-size: 3.7vw;
    margin-left: 13%;
    margin-bottom: 2%;
    line-height: 20px;
    display: none;
    margin-right: 1%;
  }

  .A-sec3-two h4:hover + .A-sec3-h-p1 {
    display: block;
  }
}

/* section 5 */

.A-se5 {
  padding: 6%;
}

.A-se5 h1 {
  font-size: 3.8vw;
  font-weight: 400;
  font-family: "Barlow";
  letter-spacing: -1px;
}

.A-se5 h1 span {
  color: #707070;
}

@media only screen and (max-width: 768px) {
  .A-se5 {
    padding: 15% 5% 10% 6%;
  }

  .A-se5 h1 {
    font-size: 6.5vw;
    font-weight: 400;
    font-family: "Barlow";
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 360px) {
  .A-se5 {
    padding: 4%;
    margin: 15% 0 8% 0;
    background-color: white;
  }

  .A-se5 h1 {
    font-size: 8.7vw;
    line-height: 50px;
    font-weight: 400;
    font-family: "Barlow";
    letter-spacing: -1px;
    padding-right: 5%;
  }
  .A-se5 h1 br {
    display: none;
  }
}

/* section 6 */

.A-sec6 {
  height: 100vh;
  width: 100%;
  padding: 0% 6% 5% 6%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}

.A-sec6-one {
  height: 80vh;
  width: 27vw;
  background-color: #151515;
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.A-sec6-img1 {
  height: 20vh;
  width: 13.5vw;
  border-right: 1px solid rgb(123, 116, 116);
  border-bottom: 1px solid rgb(136, 128, 128);
 
}

.A-sec6-two {
  display: flex;
  background-color: #161616;
  padding: 2%;
  padding-right: 5%;
  height: 80vh;
  border-radius: 3px;
}

.A-sec6-two-img-d1 {
  margin-right: 3%;
}

.A-sec6-two-img1 {
  height: 35vw;
  width: 22vw;
}

.A-sec6-d2-content h1 {
  font-size: 2.5vw;
  font-weight: 400;
  margin: 6% 0 5% 0;
  color: white;
  font-family: "Barlow";
  letter-spacing: -1px;
}

.A-sec6-d2-content p {
  font-size: 1.4vw;
  margin-right: 1%;
  letter-spacing: 1px;
  font-weight: 300;
  color: white;
  opacity: 60%;
  line-height: 28px;
  margin-bottom: 5%;
  padding-bottom: 16%;
  border-bottom: 1px solid rgb(106, 97, 97);
  font-family: "Barlow";
  letter-spacing: -0.1px;
}

.A-sec6-d2-content h3 {
  color: #0f46f5;
  margin: 5% 0 0 0;
  font-size: 1.5vw;
  font-weight: 500;
  font-family: "Barlow";
  letter-spacing: -0.1px;
}

.A-sec6-two-img1-tbv {
  display: none;
}

@media only screen and (max-width: 768px) {
  .A-sec6 {
    height: auto;
    width: 100%;
    padding: 0% 7% 15% 7%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .A-sec6-one {
    height: auto;
    width: 100%;
    background-color: #292828;
    border-radius: 3px;
    margin-bottom: 4%;
  }
  .A-sec6-img1 {
    height: auto;
    width: 100%;
  }

  .A-sec6-two {
    display: flex;
    flex-direction: column;
    background-color: #161616;
    padding: 1%;
    height: 100%;
    border-radius: 2px;
  }

  .A-sec6-two-img-d1 {
    margin-right: 0%;
    padding: 3% 3% 0% 3%;
  }

  .A-sec6-two-img1 {
    display: none;
  }

  .A-sec6-two-img1-tbv {
    display: block;
    height: 80vh;
    width: 100%;
  }

  .A-sec6-d2-content {
    padding: 0% 4%;
  }

  .A-sec6-d2-content h1 {
    font-size: 4vw;
    opacity: 90%;
    font-weight: 400;
    margin: 9% 0 3% 0;
    color: #ffffff;
  }

  .A-sec6-d2-content p {
    font-size: 2.35vw;
    margin-right: 5%;
    letter-spacing: 1px;
    font-weight: 400;
    color: #ffffff;
    opacity: 60%;
    line-height: 35px;
    margin-bottom: 1%;
    padding-bottom: 5%;
    border-bottom: 1px solid rgb(106, 97, 97);
  }

  .A-sec6-d2-content h3 {
    color: #0f46f5;
    margin: 6% 0 6% 0;
    font-size: 2.8vw;
    font-weight: 500;
    letter-spacing: -1px;
  }
}

@media only screen and (max-width: 360px) {
  .A-sec6 {
    height: auto;
    width: 100%;
    padding: 0% 0% 18% 0%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .A-sec6-one {
    height: auto;
    width: 100%;
    background-color: #232121;
    border-radius: 3px;
    margin-bottom: 25%;
  }
  .A-sec6-img1 {
    height: auto;
    width: 100%;
  }

  .A-sec6-two-img1-tbv {
    display: block;
    height: 50vh;
    width: 100%;
  }

  .A-sec6-two {
    background-color: #161616;
    padding: 1.7%;
    height: 100%;
    border-radius: 2px;
  }

  .A-sec6-two-img-d1 {
    margin-right: 0%;
    padding: 4% 3% 0% 3%;
  }

  .A-sec6-two-img1 {
    height: 10vh;
    width: 100%;
    display: none;
  }

  .A-sec6-d2-content {
    padding: 0% 1% 0 3.5%;
  }

  .A-sec6-d2-content h1 {
    font-size: 7vw;
    letter-spacing: -0.7px;
    opacity: 90%;
    font-weight: 400;
    margin: 13% 0 8% 0;
    color: white;
  }

  .A-sec6-d2-content p {
    font-size: 4.1vw;
    font-weight: 500;
    color: white;
    opacity: 60%;
    letter-spacing: -0.7px;
    line-height: 27px;
    margin-bottom: 5%;
    padding-bottom: 7%;
    border-bottom: 1px solid rgb(77, 71, 71);
  }

  .A-sec6-d2-content h3 {
    color: #0f46f5;
    margin: 10% 0 8% 0;
    font-size: 4vw;
    font-weight: 500;
    letter-spacing: -0.7px;
  }
}

/* circles section */

.zoom-in-effect {
  transition: transform 0.2s; /* Set the transition time for smooth effect */
}
.zoom-in-effect:hover {
  transform: scale(1.03); /* Increase the scale to zoom in on hover */
}

/* Tablet Styles */

.main_circle {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  background-color: black;
  padding: 10% 3%;
}
.circle {
  background-size: cover;
  border: 1px solid #fff;
  border-radius: 450px;
  width: 270px;
  height: 270px;
}
.circle:hover {
  border: 1px solid #0f46f5;
  width: 270px;
  height: 270px;
  background-image: url(/src/Simages/123back.png);
  background-size: "cover";
}
.text {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  position: absolute;
  /* padding: 100px; */
  padding: 125px 65px 100px 75px;
  font-family: "Barlow";
}
.number {
  color: #fff;
  font-size: 200px;
  font-family: Inter;
  font-weight: 300;
  line-height: 88px;
  letter-spacing: -1.2px;
  opacity: 0.1;
  padding: 90px 90px 80px 80px;
}
.text4 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  /* padding: 90px; */
  padding: 125px 90px 10px 95px;
}
