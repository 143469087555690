@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");

.H-sec1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 7% 5% 3% 9%;
  background-image: url(/src/Simages/H-sec1-bg.png);
  background-size: cover;
  background-repeat: none;
  height: auto;
  width: 100%;
}

.H-sec1-first {
  align-self: center;
}
.H-sec1-first span {
  background-color: #efefef;
  padding: 6px;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
  font-size: 0.9vw;
  border-radius: 5px;
}

.H-sec1-first h1 {
  font-size: 4.1vw;
  font-weight: 400;
  margin-top: 1.5rem;
  font-family: "inter";
  letter-spacing: -1.2px;
  line-height: 57px;
  margin-bottom: 4%;
}
.H-sec1-first h1 span {
  background: none;
  font-size: 4.1vw;
  font-weight: 200;
  letter-spacing: -1.2px;
  font-family: "inter";
  padding-left: 1px;
}

.H-sec1-first h1::first-line {
  color: #707070;
}

.h-sec1-btn {
  background-color: black;
  color: white;
  padding: 1.5% 0.6% 1.5% 1.7%;
  font-weight: 500;
  margin-top: 5rem;
  font-size: 0.9vw;
  border: none;
  border-radius: 3px;
  font-family: "Space Grotesk";
  margin-left: 4px;
  text-decoration: none;
}

.h-sec1-btn span {
  font-size: 1.2vw;
  background: none;
  color: #514e4e;
}

.h-sec1-A1 {
  padding: 0 7px 0 0px;
  position: relative;
  top: -1px;
  width: 1.7vw;
}

.H-sec1-second {
  height: 100%;
  width: 100%;
  padding: 2%;
}

.H-sec1-img1 {
  width: 30vw;
  height: auto;
  object-fit: cover;
}

.S-sec2-a {
  display: none;
}

#sec2-oc {
  color: #9c9c9c;
  font-size: 1vw;
  font-weight: 500;
  position: absolute;
  bottom: -190px;
  left: 30px;
}

@media only screen and (max-width: 768px) {
  .H-sec1 {
    display: grid;
    grid-template-columns: 1fr;
    padding: 4% 5% 3% 7%;
    background-image: url(/src/Simages/H-sec1-bg.png);
    background-size: cover;
    background-repeat: none;
    height: 100vh;
    width: 100%;
  }

  .H-sec1-first {
    align-self: center;
  }
  .H-sec1-first span {
    background-color: #efefef;
    padding: 0.7% 0.8%;
    font-size: 1.5vw;
    font-family: "Space Grotesk";
  }

  .H-sec1-first h1 {
    font-size: 7.6vw;
    font-weight: 200;
    line-height: 60px;
    letter-spacing: -1.2px;
    margin-top: 1.5rem;
    font-family: "inter";
  }
  .H-sec1-first h1 span {
    background: none;
    font-size: 7.6vw;
    font-weight: 200;
    letter-spacing: -1.2px;
    font-family: "inter";
  }

  .h-sec1-btn {
    background-color: black;
    color: white;
    padding: 1.8% 1% 1.8% 2%;
    font-weight: 500;
    margin-top: 2.5rem;
    font-size: 2vw;
    border: none;
    border-radius: 3px;
    font-family: "Space Grotesk";
    display: inline;
    width: 22vw;
  }

  .h-sec1-btn span {
    font-size: 2vw;
    padding-left: 5%;
    background: none;
    color: #706c6c;
  }

  .h-sec1-A1 {
    position: relative;
    width: 5vw;
  }

  .H-sec1-second {
    display: none;
  }
  .H-sec1-img1 {
    display: none;
  }

  #sec2-oc {
    color: #9c9c9c;
    font-size: 2.3vw;
    font-weight: 400;
    position: absolute;
    bottom: -40px;
    left: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec1 {
    background-image: url(/src/Simages/H-sec1-bg.png);
    background-size: cover;
    background-repeat: none;
    height: 100vh;
    width: 100%;
  }
  .H-sec1-first {
    align-self: center;
    margin-top: 5%;
  }
  .H-sec1-first span {
    background-color: #efefef;
    font-size: 3vw;
    font-weight: 500;
    border-radius: 3px;
    padding: 1.5%;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
  }

  .H-sec1-first h1 {
    font-size: 11vw;
    font-weight: 300;
    line-height: 50px;
    margin-top: 13%;
    letter-spacing: -1.2px;
    font-family: "inter";
  }

  .H-sec1-first h1 span {
    background: none;
    font-size: 10vw;
    font-weight: 300;
    color: #707070;
  }

  .H-sec1-first h1 br {
    display: none;
  }

  .h-sec1-btn {
    background-color: black;
    color: white;
    font-weight: 500;
    margin-top: 2.5rem;
    border: none;
    border-radius: 3px;
    font-family: "Space Grotesk";
    width: 40vw;
    font-size: 4vw;
  }

  .h-sec1-btn span {
    background: none;
  }

  .h-sec1-A1 {
    position: relative;
    width: 10vw;
  }

  #sec2-oc {
    color: #9c9c9c;
    font-size: 3.8vw;
    font-weight: 400;
    position: absolute;
    bottom: -50px;
    left: 30px;
  }
}

/* section 2 imgs */

.h-sec2-img-d {
  width: auto;
  flex-grow: 1;
}

@media only screen and (max-width: 768px) {
  .h-sec2-imgs-tabv {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .h-sec2-imgs-mbv {
    display: none;
  }
}

/* section 3 */

.H-sec3 {
  display: grid;
  grid-template-columns: 0.9fr 1.5fr;
  height: auto;
  width: 100%;
  padding: 8% 6% 6% 6%;
}

.h-sec3-first span {
  background-color: #efefef;
  padding: 1%;
  border-radius: 2px;
  font-family: "Space Grotesk";
  font-weight: 500;
  margin-left: 0%;
  margin-top: 5%;
  font-size: 0.8vw;
}

.h-sec3-second h1 {
  font-size: 4.3vw;
  font-weight: 300;
  letter-spacing: -1.2px;
  font-family: "inter";
  line-height: 60px;
  margin-top: 4%;
}

.h-sec3-second h1 span {
  color: #707070;
}

.h-sec3-second p {
  margin-top: 4%;
  margin-bottom: 5%;
  font-size: 1.5vw;
  padding-right: 10%;
  font-family: "Barlow";
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.1px;
  color: #161616;
}

.H-sec3-B {
  margin-top: 3%;
  font-size: 0.9vw;
  background-color: white;
  padding: 1.3%;
  font-weight: 600;
  border-radius: 5px;
  font-family: "Space Grotesk";
  letter-spacing: -0.4px;
  border: 1px solid #cecece;
  margin-bottom: 5%;
  text-decoration: none;
  color: #000000;
}

@media only screen and (max-width: 768px) {
  .H-sec3 {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    padding: 15% 0% 1% 6%;
    margin: 0;
  }

  .h-sec3-first span {
    background-color: #efefef;
    padding: 0.5% 1%;
    border-radius: 2px;
    font-family: "Space Grotesk";
    font-weight: 500;
    margin-left: 0%;
    margin-top: 5%;
    font-size: 1.6vw;
    letter-spacing: 1px;
  }

  .h-sec3-second h1 {
    margin-top: 7%;
    font-size: 5.5vw;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-family: "inter";
  }
  .h-sec3-second h1 br {
    display: none;
  }

  .h-sec3-second p {
    margin-top: 5%;
    font-size: 2.7vw;
    font-family: "Barlow";
    line-height: 30px;
    letter-spacing: -0.1px;
    font-weight: 300;
    color: #161616;
  }

  .H-sec3-B {
    margin-top: 6%;
    font-size: 1.9vw;
    background-color: white;
    padding: 1.5%;
    font-weight: 500;
    color: #000000;
    font-family: "Sapce Grotesk";
    border-radius: 3px;
    letter-spacing: -0.4px;
    border: 2px solid #cecece;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec3 {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    padding: 20% 0% 5% 6%;
  }

  .h-sec3-first span {
    background-color: #efefef;
    padding: 1.5%;
    font-size: 3.5vw;
    letter-spacing: 1px;
  }

  .h-sec3-second h1 {
    margin-top: 10%;
    font-size: 8.5vw;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-family: "Inter";
    line-height: 44px;
  }

  .h-sec3-second h1 br {
    display: none;
  }

  .h-sec3-second p {
    margin-top: 10%;
    font-size: 4.2vw;
    color: #161616;
    letter-spacing: -0.1px;
    line-height: 26px;
  }

  .H-sec3-B {
    margin-top: 10%;
    font-size: 3.2vw;
    background-color: white;
    padding: 2.5%;
    font-weight: 600;
    font-family: "Space Grotesk";
    border-radius: 4px;
    border: 1px solid #cecece;
    letter-spacing: -0.28px;
  }
}

/* section 4 */

.H-sec4 {
  height: 100%;
  width: 100%;
}

.h-sec4-img {
  width: 100%;
  padding: 0 6% 7% 6%;
  height: 100%;
}
.h-sec4-img-mbv {
  display: none;
}

@media only screen and (max-width: 768px) {
  .H-sec4 {
    height: auto;
    width: 100%;
  }

  .h-sec4-img {
    width: 100%;
    padding: 6%;
    height: auto;
  }
  .h-sec4-img-mbv {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec4 {
    height: 50vh;
    width: 100%;
  }

  .h-sec4-img {
    width: 100%;

    height: 50vh;
    display: none;
  }

  .h-sec4-img-mbv {
    display: block;
    padding: 4%;
  }
}

/* section 5 */

.H-sec5 {
  width: 100%;
  padding: 0% 4% 5% 6%;
  display: grid;
  grid-template-columns: 0.7fr 1.5fr;
}

.h-sec5-one span {
  background-color: #efefef;
  font-size: 0.9vw;
  font-family: "Space Grotesk";
  padding: 0.8% 1%;
  border-radius: 3px;
  font-weight: 500;
}

.h-sec5-one h1 {
  font-size: 4.1vw;
  margin-top: 4%;
  font-weight: 600;
  font-family: "Barlow";
  letter-spacing: 1px;
  color: #000000;
}

.h-sec5-two {
  margin-top: 8%;
}

.h-sec5-two-d1 {
  display: flex;
  margin-top: 1%;
  justify-content: start;
  align-items: center;
}

.h-sec5-two-hw1 {
  justify-content: start;
  align-items: center;
}
.h-sec5-two-hw1 h1 {
  font-size: 3.5vw;
  font-weight: 300;
  color: #9c9c9c;
  letter-spacing: -2px;
}

.sss2 {
  height: auto;
  width: auto;
  justify-content: start;
  padding-left: 5%;
}

.h-sec5-hwimg {
  width: 18vw;
  visibility: hidden;
}
.h-sec5-two-hw1 h1:hover {
  color: rgb(8, 8, 227);
  opacity: 90%;
}

.h-sec5-two-d1:hover .h-sec5-hwimg {
  visibility: visible;
}

.h-sec5-two-btn {
  background-color: black;
  color: #efefef;
  font-size: 0.9vw;
  padding: 1.2% 2%;
  border-radius: 2px;
  margin-top: 5%;
  font-family: "Space Grotesk";
  cursor: pointer;
  border: none;
  text-decoration: none;
}

.h-sec5-two-btn span {
  color: #504d4d;
  margin-left: 10px;
}

.h-sec5-two-btn:hover {
  box-shadow: 2px 2px 5px 0px rgba(39, 37, 37, 0.5);
}

.h-sec5-A1 {
  padding-left: 0.3rem;
  position: relative;
  height: 0.85vw;
  top: 0px;
  margin-left: 4px;
}

@media only screen and (max-width: 768px) {
  .H-sec5 {
    width: 100%;
    padding: 6% 7% 5% 6%;
    display: grid;
    /* grid-template-columns: 1fr 1.5fr 1fr; */
    grid-template-columns: 1fr;
  }

  .h-sec5-one span {
    background-color: #efefef;
    font-size: 1.5vw;
    font-family: "Space Grotesk";
    padding: 0.8% 1%;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .h-sec5-one h1 {
    font-size: 6vw;
    margin-top: 5%;
    font-weight: 600;
    margin-bottom: 2%;
  }

  .h-sec5-two-hw1 h1 {
    font-size: 5.5vw;
    font-family: "Barlow";
    font-weight: 300;
    color: #000000;
    opacity: 10%;
    margin: 3% 0;
    letter-spacing: -2px;
  }

  .sss2 {
    display: none;
  }

  .h-sec5-two-btn {
    background-color: black;
    color: #efefef;
    font-size: 2vw;
    padding: 2.2% 2.5% 2.2% 3.5%;
    border-radius: 3px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    margin: 8% 0;
  }

  .h-sec5-A1 {
    padding-left: 0.5rem;
    position: relative;
    top: 2px;
    width: 4vw;
    height: 2.4vh;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec5 {
    width: 100%;
    padding: 18% 7% 15% 6%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .h-sec5-one span {
    background-color: #efefef;
    font-size: 3.5vw;
    font-weight: 500;
    padding: 1% 2%;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
  }

  .h-sec5-one h1 {
    font-size: 11vw;
    margin: 10% 0;
    font-weight: 600;
    font-family: "Barlow";
  }

  .h-sec5-two-hw1 h1 {
    font-size: 8vw;
    padding: 7% 0;
    font-family: "Barlow";
    color: #000000;
    font-size: -2px;
    opacity: 15%;
  }

  .sss2 {
    display: none;
  }

  .h-sec5-two-btn {
    background-color: black;
    color: #efefef;
    font-size: 4vw;
    font-weight: 500;
    padding: 3.2% 6%;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    margin: 13% 0;
  }

  .h-sec5-A1 {
    padding-left: 0.5rem;
    width: 7.3vw;
    position: relative;
    top: 2.5px;
  }
}

/* section 6 inner 1 */

.H-sec6-one {
  width: 100%;
  height: auto;
  padding: 5% 5% 5% 6%;
  display: grid;
  grid-template-columns: 0.9fr 1.5fr;
  background-color: #161616;
}

.H-sec6-one-d1 span {
  background-color: #323131;

  font-size: 0.8vw;
  font-family: "Space Grotesk";
  padding: 0.5% 1%;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #f8f8f8;
}

.H-sec6-one-d2 h1 {
  margin-top: 1%;
  font-size: 3.3vw;
  font-family: "Barlow";
  letter-spacing: -1px;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
}
.H-sec6-one-d2 h1 span {
  color: #707070;
}

.h-sec6-one-bb1 {
  margin: 5% 0;
}
.H-sec6-one-d2 p {
  color: #9c9c9c;
  font-size: 0.8vw;
  font-weight: 400;
  font-family: "Barlow";
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .H-sec6-one {
    width: 100%;
    height: auto;
    padding: 10% 5% 5% 6%;
    display: grid;
    /* grid-template-columns: 1fr 1.5fr 1fr; */
    grid-template-columns: 1fr;
    background-color: #161616;
  }
  .H-sec6-one-d1 {
    height: auto;
  }

  .H-sec6-one-d1 span {
    background-color: #353333;
    font-size: 1.3vw;
    font-family: "Space Grotesk";
    padding: 0.8% 1%;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 0.7px;
  }

  .H-sec6-one-d2 h1 {
    font-size: 5.5vw;
    font-weight: 400;
    color: #efefef;
    margin: 4% 0;
    font-family: "Barlow";
    line-height: 49px;
  }

  .h-sec6-one-bb1 {
    margin: 2% 0 5% 0;
    width: 5vw;
  }
  .H-sec6-one-d2 p {
    color: #9c9c9c;
    font-size: 2.5vw;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec6-one {
    width: 100%;
    height: auto;
    padding: 22% 5% 5% 6%;
    display: grid;
    /* grid-template-columns: 1fr 1.5fr 1fr; */
    grid-template-columns: 1fr;
    background-color: #161616;
  }
  .H-sec6-one-d1 {
    height: auto;
  }

  .H-sec6-one-d1 span {
    color: rgb(236, 233, 233);
    background-color: #322f2f;
    border: 5px;
    font-size: 3vw;
    padding: 1.5%;
    letter-spacing: 0.7px;
  }

  .H-sec6-one-d2 h1 {
    font-size: 8.5vw;
    font-weight: 400;
    color: #efefef;
    margin: 15% 0 8% 0;
  }

  .h-sec6-one-bb1 {
    margin: 2% 0;
    width: 10vw;
  }
  .H-sec6-one-d2 p {
    font-size: 3.3vw;
    color: #9c9c9c;
    opacity: 70%;
    margin: 10% 0 12% 0;
  }

  .H-sec6-one-d2 p br {
    display: none;
  }
}

/* section 6 inner two */

.H-sec6-three {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 0.6fr 1fr;
  background-color: #161616;
  padding: 2% 7% 2% 6%;
  grid-column-gap: 20px;
  font-family: "Barlow";
}

.H-sec6-three-d1 {
  padding: 0 5% 0 0;
  color: aliceblue;
  /* display: none; */
}

.H-sec6-three-d1-vd1 {
  height: 185px;
  width: 100%;
}

.H-sec6-three-d2 {
  display: flex;
  column-gap: 4%;
}
.H-sec6-three-d2-img {
  margin-top: 1%;
}

.H-sec6-three-d2-content {
  height: auto;
}

.H-sec6-three-d2-content-s1 {
  height: 185px;
}

.H-sec6-three-d2-content-s1 h3 {
  font-size: 1.6vw;
  font-weight: 500;
  color: #707070;
}

.H-sec6-three-d2-content-s1 h3:hover {
  color: #ffffff;
}

.H-sec6-three-d2-content-s1:hover + .H-sec6-three-d1-vd1 {
  visibility: visible;
}

.H-sec6-three-d2-content-s1 p {
  color: #707070;
  font-weight: 400;
  font-size: 1vw;
  margin-top: 3%;
}

@media only screen and (max-width: 768px) {
  .H-sec6-three {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    background-color: #161616;
    padding: 2% 5% 2% 5%;
    grid-column-gap: 20px;
  }

  .H-sec6-three-d1 {
    padding: 0 5% 0 0;
    color: aliceblue;
    display: none;
  }

  .H-sec6-three-d2 {
    display: flex;
    column-gap: 4%;
    padding-left: 12%;
  }
  .H-sec6-three-d2-img {
    margin-top: 1.5%;
  }

  .H-sec6-three-d2-img-1 {
    height: 100vh;
  }

  .H-sec6-three-d2-content {
    height: auto;
  }

  .H-sec6-three-d2-content-s1 {
    height: 155px;
  }

  .H-sec6-three-d2-content-s1 h3 {
    font-size: 3.2vw;
    font-weight: 500;
    color: #707070;
  }

  #h-tabv-sec3-h1 {
    color: #ffffff;
  }

  .H-sec6-three-d2-content-s1 p {
    color: #707070;
    font-size: 2.2vw;
    margin-top: 4%;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec6-three {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr;
    background-color: #161616;
    padding: 2% 5% 8% 6%;
    grid-column-gap: 20px;
  }

  .H-sec6-three-d1 {
    padding: 0 5% 0 0;
    color: aliceblue;
    display: none;
  }

  .H-sec6-three-d2 {
    display: flex;
    column-gap: 4%;
    padding-left: 2%;
  }
  .H-sec6-three-d2-img {
    margin-top: 3%;
  }

  .H-sec6-three-d2-img-1 {
    height: 80vh;
    width: 2vw;
  }

  .H-sec6-three-d2-content {
    height: auto;
  }

  .H-sec6-three-d2-content-s1 {
    height: 125px;
  }

  .H-sec6-three-d2-content-s1 h3 {
    font-size: 5.5vw;
    font-weight: 500;
    color: #9c9c9c;
  }
  .H-sec6-three-d2-content-s1 p {
    color: #9c9c9c;
    opacity: 60%;
    font-size: 4vw;
    margin-top: 2%;
  }

  .H-sec6-three-d2-content-s1 p br {
    display: none;
  }
}

/* section 7 */

.H-sec7 {
  display: grid;
  color: #efefef;
  background-color: #161616;
  display: flex;
  height: auto;
  padding: 3% 7% 10% 7%;
}

.H-sec7-one {
  background-color: #353232;
  margin: 1px;
  width: 21vw;
  padding: 2% 3% 3% 3%;
  border-radius: 4px;
}

.H-sec7-one h1 {
  font-size: 3.4vw;
  font-weight: 400;
  font-family: "Barlow";
  letter-spacing: -1px;
}

.H-sec7-one p {
  font-size: 1.2vw;
  font-weight: 400;
  font-family: "Space Grotesk";
  letter-spacing: -1px;
  margin-top: 6%;
}

@media only screen and (max-width: 768px) {
  .H-sec7 {
    color: #efefef;
    background-color: #161616;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    padding: 3% 12% 10% 16%;
  }

  .H-sec7-one {
    background-color: #353232;
    margin: 1px;
    width: auto;
    padding: 5% 2% 8% 14%;
    border-radius: 2px;
  }

  .H-sec7-one h1 {
    font-size: 7vw;
    font-weight: 400;
    margin-bottom: 4%;
    color: #ffffff;
    letter-spacing: -0.7px;
  }

  .H-sec7-one p {
    font-size: 2.5vw;
    font-weight: 400;
    margin-bottom: 7%;
    letter-spacing: -0.7px;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec7 {
    color: #efefef;
    background-color: #161616;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    padding: 3% 5% 15% 5%;
  }

  .H-sec7-one {
    background-color: #353232;
    margin: 1px;
    width: auto;
    padding: 8% 2% 8% 10%;
    border-radius: 2px;
  }

  .H-sec7-one h1 {
    font-size: 10vw;
    font-weight: 400;
    margin-bottom: 10%;
  }

  .H-sec7-one p {
    font-size: 4.1vw;
    font-weight: 400;
    color: white;
    margin-bottom: 7%;
  }
}

/* section 8 */

.H-sec8-one {
  width: 100%;
  padding: 6% 6% 7% 7%;
  display: grid;
  grid-template-columns: 0.9fr 1.5fr;
}

.H-sec8-one-d1 span {
  background-color: #efefef;
  font-size: 0.8vw;
  font-family: "Space Grotesk";
  padding: 0.5% 1%;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1px;
}

.H-sec8-one-d2 h1 {
  font-size: 3.3vw;
  font-weight: 400;
  letter-spacing: -1px;
  font-family: "Barlow";
  margin-bottom: 1.2%;
}

.H-sec8-one-d2 h1 span {
  color: #707070;
}

.H-sec8-one-d2 p {
  font-size: 0.89vw;
  color: #9c9c9c;
  margin-top: 4%;
  font-family: "Barlow";
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .H-sec8-one {
    width: 100%;
    padding: 15% 6% 7% 6%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .H-sec8-one-d1 span {
    background-color: #efefef;
    border-radius: 2px;
    font-size: 1.5vw;
  }

  .H-sec8-one-d2 h1 {
    font-size: 5.9vw;
    font-weight: 400;
    margin: 6% 0 4% 0;
    letter-spacing: -1px;
  }

  .H-sec8-one-d2 p {
    font-size: 2.1vw;
    color: #9c9c9c;

    margin-top: 3%;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec8-one {
    width: 100%;
    padding: 20% 6% 7% 6%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .H-sec8-one-d1 span {
    background-color: #efefef;
    border-radius: 2px;
    font-size: 3.2vw;
    font-weight: 400;
    padding: 1.5% 2%;
    letter-spacing: 1px;
  }

  .H-sec8-one-d2 h1 {
    font-size: 8.2vw;
    font-weight: 400;
    margin: 20% 0 10% 0;
  }

  .H-sec8-one-d2 p {
    font-size: 3.2vw;
    line-height: 20px;
    color: #161616;
    opacity: 60%;
    margin-top: 10%;
  }

  .H-sec8-one-d2 p br {
    display: none;
  }
}

/* section 2 two */

.H-sec8-two {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2% 9% 10% 9%;
}

.h-sec8-two-d1 {
  width: 27vw;
  height: auto;
  background-color: #f8f8f8;
  /* margin: 1%; */
  padding: 3%;
  margin: 0.01%;
  border-right: 1px solid #cbcaca;
  border-bottom: 1px solid #cbcbcb;
  cursor: pointer;
}

.h-sec8-two-d1:hover {
  /* box-shadow: 0 0 20px rgba(15, 15, 15, 0.5); */
  background-color: #f0f0f0;
}

.h-sec8-two-img1 {
  width: 25.3vw;
}

.h-sec8-two-d1 h5 {
  font-size: 1.4vw;
  color: #161616;
  font-weight: 600;
  margin: 4% 0;
  font-family: "Space Grotesk";
  letter-spacing: -0.1px;
}

.h-sec8-two-d1 p {
  font-size: 1.2vw;
  margin: 3% 0 10% 0;
  line-height: 25.2px;
  color: #707070;
  font-family: "Barlow";
  font-weight: 400;
}

.h-sec8-btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .H-sec8-two {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 8% 7% 10% 7%;
    background-color: #f8f8f8;
  }

  .h-sec8-two-d1 {
    width: 42.9vw;
    height: auto;
    background-color: rgb(245, 245, 245);
    padding: 3% 4.5%;
  }

  .h-sec8-two-img1 {
    width: 39vw;
  }

  .h-sec8-two-d1 h5 {
    font-size: 3vw;
    font-weight: 500;
    margin: 5% 0;
  }

  .h-sec8-two-d1 p {
    font-size: 2vw;
    margin: 3% 0 15% 0;
    color: black;
    opacity: 60%;
  }
  .tab-2 {
    display: none;
  }

  .h-sec8-btn {
    display: block;
  }

  .h-sec8-btn-b {
    font-size: 2.3vw;
    background-color: #161616;
    color: aliceblue;
    border: none;
    padding: 4% 5% 4% 8%;
    margin-top: 20%;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Space Grotesk";
  }

  .h-sec8-btn-b span {
    color: #504d4d;
    padding-left: 10px;
    font-size: 2.5vw;
  }
  .h-sec8-A1 {
    width: 3.2vw;
    height: 2.5vh;
    padding-right: 3%;
    margin-left: 10px;
    position: relative;
    top: 1.5px;
  }

  .h-sec8-two-bdr {
    border-right: none;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec8-two {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding: 8% 0% 5% 0%;
    background-color: white;
  }

  .h-sec8-two-d1 {
    width: 100%;
    height: auto;
    background-color: white;
    padding: 3% 4.5%;
    border-bottom: 1px solid #f1eeee;
  }

  .h-sec8-two-img1 {
    width: 100%;
  }

  .h-sec8-two-d1 h5 {
    font-size: 5.5vw;
    font-weight: 500;
    margin: 5% 0;
    font-family: "Barlow";
  }

  .h-sec8-two-d1 p {
    font-size: 4.2vw;
    margin: 3% 0 10% 0;
    font-family: "Barlow";
    line-height: 20px;
    color: #707070;
  }
  .tab-2 {
    display: none;
  }

  .h-sec8-btn {
    display: block;
  }

  .h-sec8-btn-b {
    font-size: 4vw;
    background-color: #161616;
    color: aliceblue;
    border: none;
    padding: 3% 5%;
    margin-top: 15%;
    font-weight: 500;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 5%;
  }
  .h-sec8-A1 {
    width: 6vw;
    padding-right: 2%;
    margin-left: 15px;
    position: relative;
    top: 4px;
  }

  .h-sec8-two-d1-mbvb {
    border-right: none;
  }
}

/* section 9 one */

.H-sec9-one {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.9fr 1.5fr;
  padding: 5% 5% 5% 7%;
  background-color: #f8f8f8;
}

.h-sec9-one-d1 span {
  background-color: #efefef;
  font-size: 0.8vw;
  font-family: "Space Grotesk";
  padding: 0.5% 1%;
  border-radius: 5px;
  font-weight: 500;
}

.h-sec9-one-d2 h1 {
  font-size: 3.5vw;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 3%;
  font-family: "Barlow";
  letter-spacing: -1px;
}

.h-sec9-one-d2 h1 span {
  color: #707070;
}

@media only screen and (max-width: 768px) {
  .H-sec9-one {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding: 10% 6% 5% 6%;
  }

  .h-sec9-one-d1 span {
    background-color: #efefef;
    border-radius: 3px;
    font-size: 1.5vw;
    padding: 0.7%;
    color: black;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: "Space Grotesk";
  }

  .h-sec9-one-d2 h1 {
    font-size: 5.5vw;
    font-weight: 400;
    margin-top: 7%;
    margin-bottom: 4%;
    letter-spacing: -1px;
  }
  .h-sec8-b {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec9-one {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    padding: 30% 5% 5% 5%;
  }

  .h-sec9-one-d1 span {
    background-color: #efefef;
    border-radius: 2px;
    font-size: 3.1vw;
    padding: 2%;
    letter-spacing: 1px;
    color: black;
    font-weight: 500;
  }

  .h-sec9-one-d2 h1 {
    font-size: 11vw;
    font-weight: 400;
    margin-top: 15%;
    margin-bottom: 8%;
  }
  .h-sec8-b {
    display: none;
  }
}

/* section 9 two */

.h-sec9-two {
  height: 100vh;
  width: 100%;
  display: grid;
  background-color: #f8f8f8;
  grid-template-columns: 1fr 1fr;
  padding: 0% 10% 0% 10%;
}

.h-sec9-two-d1 {
  width: 100%;
  height: 90vh;
  padding: 7%;
  background-color: #161616;
}

.h-sec9-two-d1-img1 {
  width: 100%;
  height: 100%;
}

.h-sec9-two-d2 {
  height: 90vh;
  background-color: #161616;
  padding: 15% 5% 5% 5%;
}

.h-sec9-two-d2 p {
  font-size: 1.6vw;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-family: "Barlow";
  color: #dad3d3;
  font-weight: 300;
  margin-bottom: 8%;
  padding-bottom: 6%;
  padding-right: 20%;
  margin-left: 1%;
  border-bottom: 1px solid rgb(81, 77, 77);
}

.h-sec9-two-d2 h1 {
  font-size: 2.6vw;
  color: aliceblue;
  font-weight: 400;
  font-family: "Barlow";
  margin: 10% 0 3% 0;
  letter-spacing: -1px;
}

.h-sec9-two-d2 h6 {
  color: #0f46f5;
  font-size: 1.7vw;
  font-weight: 500;
  font-family: "Barlow";
  letter-spacing: -1px;
}
.h-sec9-two-mbv-img1 {
  display: none;
}
@media only screen and (max-width: 768px) {
  .h-sec9-two {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 3% 6% 2% 6%;
  }

  .h-sec9-two-d1 {
    width: 100%;
    height: 100vh;
  }

  .h-sec9-two-d1-img1 {
    width: 100%;
    height: 100vh;
  }

  .h-sec9-two-d2 {
    height: auto;
    background-color: #161616;
    padding: 5% 3% 5% 5%;
  }

  .h-sec9-two-d2 p {
    font-size: 2.9vw;
    letter-spacing: -0.1px;
    line-height: 35px;
    color: #ffffff;
    opacity: 87%;
    font-weight: 300;
    margin-bottom: 0%;
    border-bottom: none;
  }

  .h-sec9-two-d2 h1 {
    font-size: 3.5vw;
    color: aliceblue;
    font-weight: 300;
    margin: 1% 0;
  }

  .h-sec9-two-d2 h6 {
    color: #0f46f5;
    font-size: 3.2vw;
    font-weight: 400;
    margin-bottom: 3%;
  }
  .h-sec9-two-mbv-img1 {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .h-sec9-two {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 10% 0% 2% 0%;
  }

  .h-sec9-two-d1 {
    width: 100%;
    height: 68vh;
  }

  .h-sec9-two-d1-img1 {
    width: 100%;

    display: none;
  }
  .h-sec9-two-mbv-img1 {
    display: block;
    height: auto;
  }

  .h-sec9-two-d2 {
    height: auto;
    background-color: #161616;
    padding: 8% 6% 8% 6%;
  }

  .h-sec9-two-d2 p {
    font-size: 4.7vw;
    letter-spacing: -0.1px;
    line-height: 28px;
    color: #ffffff;
    opacity: 75%;
    font-weight: 300;
    margin-bottom: 0%;
  }

  .h-sec9-two-d2 h1 {
    font-size: 8vw;
    color: aliceblue;
    font-weight: 400;
    margin: 0% 0 2% 0;
    font-family: "Barlow";
    letter-spacing: -1px;
  }

  .h-sec9-two-d2 h6 {
    color: #0f46f5;
    font-size: 5.5vw;
    font-weight: 400;
    margin-bottom: 7%;
  }
}

/* section 10 */

.H-sec10-one {
  padding: 8% 6% 13% 6%;
  display: grid;
  grid-template-columns: 0.08fr 1fr 0.08fr;
  background-color: #f8f8f8;
}

.h-sec10-LA-d1 {
  align-self: center;
  margin-top: 80%;
  visibility: hidden;
}

.h-sec10-RA {
  margin-left: 50%;
}

.h-sec10-LA-d1 img {
  margin-top: 0%;
  width: 2.8vw;
  /* padding-top: 180%; */
}

.h-sec10-two {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
  height: auto;
}
.small-sec10-two {
  display: flex;
}
.h-sec10-j-img1-in1 {
  padding-right: 10px;
}

.h-sec10-two-d1 {
  border-right: 1px solid rgb(230, 222, 222);
}
.bd3 {
  border-right: none;
}

.h-sec10-3sec1 h6 {
  margin-top: 4.5%;
  margin-bottom: 0%;
  font-size: 1.1vw;
  font-weight: 500;
  font-family: "Barlow";
}

.h-sec10-3sec1 span {
  font-size: 0.9vw;
  font-weight: 400;
  font-family: "Barlow";
  color: #9c9c9c;
}

.h-sec10-3sec2 {
  margin-top: 7%;
}

.h-sec10-3sec2 p {
  font-size: 1.2vw;
  letter-spacing: 1px;
  font-weight: 300;
  font-family: "Barlow";
  color: #161616;
  line-height: 28px;
  padding-right: 10%;
}
.h-se10-mbv {
  display: none;
}
/* section 10 tablet */

@media only screen and (max-width: 768px) {
  .H-sec10-one {
    width: 100%;
    height: 30vh;
    padding: 8% 7% 0% 7%;
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.2fr;
  }

  .h-sec10-LA-d1 {
    align-self: center;
    padding-top: 80%;
  }

  .h-sec10-LA-d1 img {
    width: 6vw;
  }

  .h-sec10-two {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    padding-top: 10%;
    padding-left: 5%;
  }

  .h-sec10-j-img1-in1 {
    padding-right: 20px;
  }

  .h-sec10-3sec1 h6 {
    margin-top: 4%;
    font-size: 2.2vw;
    font-weight: 500;
  }

  .h-sec10-3sec1 span {
    color: black;
    opacity: 60%;
    font-size: 1.8vw;
  }

  .h-sec10-3sec2 {
    margin-top: 7%;
  }

  .h-sec10-3sec2 p {
    font-size: 3vw;
    letter-spacing: -0.1px;
    color: #161616;
    line-height: 35px;
    padding-right: 10%;
  }

  .h-sec10-dn {
    display: none;
  }

  .h-se10-mbv {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec10-one {
    width: 100%;
    height: 10vh;
    padding: 15% 5% 20% 12%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .h-sec10-LA-d1 {
    align-self: center;
    padding-top: 120%;
    display: none;
  }

  .h-sec10-LA-d1 img {
    width: 5vw;
  }

  .h-sec10-j-img1-in1 {
    width: 20vw;
  }

  .h-sec10-two {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    padding-top: 10%;
  }

  .h-sec10-j-img1-in1 {
    padding-right: 15px;
  }

  .h-sec10-3sec1 h6 {
    margin-top: 8%;
    font-size: 4.2vw;
    font-weight: 500;
  }

  .h-sec10-3sec1 span {
    color: black;
    font-size: 3.5vw;
    opacity: 60%;
  }

  .h-sec10-3sec2 {
    margin-top: 7%;
  }

  .h-sec10-3sec2 p {
    font-size: 5vw;
    letter-spacing: -0.1px;
    opacity: 90%;
    line-height: 30px;
    padding-right: 4%;
    font-weight: 400;
  }

  .h-se10-mbv {
    width: 10vw;
    margin-top: 12%;
    margin-right: 5%;
    display: inline-block;
  }

  .h-sec10-dn {
    display: none;
  }
}

/* section 11 */

.H-sec11-one {
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  width: 100%;
  height: auto;
  padding: 1%;
  background-color: #f8f8f8;
}

.h-sec11-one-d1 {
  height: 60vh;
  width: 100%;
}

.h-sec11-mb {
  margin-bottom: 11.5% !important;
}

.h-sec11-one-d1 img {
  width: 100%;
  height: 60vh;
}

.h-sec11-one-d2 {
  background-color: #161616;
  height: 60vh;
  padding: 8% 0% 0% 5%;
}

.h-sec11-one-d2 h1 {
  font-size: 3.7vw;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.5px;
  color: aliceblue;
  margin-bottom: 3%;
  font-family: "Barlow";
}
.h-sec11-one-d2 h1 span {
  color: #707070;
}

.h-sec11-one-d2 p {
  font-size: 1.1vw;
  margin: 5% 0 5% 0;
  font-family: "Barlow";
  color: #9c9c9c;
  line-height: 17px;
}

.h-sec11-btn {
  background-color: #0f46f5;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.9vw;
  padding: 1.3% 1% 1.4% 1.8%;
  border: none;
  cursor: pointer;
  font-family: "Space Grotesk";
  text-decoration: none;
}

.h-sec11-btn span {
  color: #ffffff;
  opacity: 50%;
  padding-left: 3px;
  padding-right: 5px;
}

.h-sec11-A1 {
  margin-left: 10px;
  position: relative;
  top: 0px;
  width: 1.2vw;
}

@media only screen and (max-width: 768px) {
  .H-sec11-one {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
    padding: 0%;
  }

  .h-sec11-one-d1 img {
    width: 100%;
    height: 50vh;
    display: none;
  }

  .h-sec11-one-d2 {
    background-color: #161616;
    height: auto;
    padding: 8% 0% 0% 6%;
  }

  .h-sec11-one-d2 h1 {
    font-size: 6.5vw;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -1px;
    color: aliceblue;
    margin-bottom: 4%;
  }

  .h-sec11-one-d2 p {
    font-size: 2.5vw;
    margin-top: 5%;
    color: aliceblue;
    opacity: 50%;
    line-height: 24px;
  }

  .h-sec11-btn {
    background-color: #0f46f5;
    border-radius: 3px;
    color: aliceblue;
    font-size: 1.9vw;
    font-weight: 500;
    padding: 1.7% 2.5% 1.7% 2%;
    border: none;
    margin-top: 0%;
    margin-bottom: 10%;
    cursor: pointer;
  }

  .h-sec11-A1 {
    margin-left: 10px;
    position: relative;
    top: 3px;
    height: 2.2vh;
    width: 2.5vw;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec11-one {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
    padding: 0%;
  }

  .h-sec11-one-d1 img {
    width: 100%;
    height: 60vh;
    display: none;
  }

  .h-sec11-one-d2 {
    background-color: #161616;
    height: auto;
    padding: 15% 4% 0% 6%;
  }

  .h-sec11-one-d2 h1 {
    font-size: 8.5vw;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0px;
    color: aliceblue;
    margin-bottom: 6%;
  }

  .h-sec11-one-d2 h1 br {
    display: none;
  }
  .ins-sec11-br1 {
    display: none;
  }

  .h-sec11-one-d2 p {
    font-size: 3.8vw;
    margin-top: 9%;
    line-height: 23px;
    color: aliceblue;
    opacity: 40%;
  }

  .h-sec11-one-d2 p br {
    display: none;
  }

  .h-sec11-btn {
    background-color: #0f46f5;
    border-radius: 3px;
    color: aliceblue;
    font-size: 3.7vw;
    padding: 3.5%;
    border: none;
    margin-top: 5%;
    margin-bottom: 15%;
    cursor: pointer;
    font-family: "Space Grotesk";
  }

  .h-sec11-A1 {
    margin-left: 7px;
    position: relative;
    top: 3.5px;
    height: 3.6vw;
    width: 5vw;
  }
}

/* ssection 12 */

.H-sec12-one {
  padding: 8% 0% 5% 7%;
  display: grid;
  grid-template-columns: 0.9fr 1.5fr;
  width: 100%;
  height: auto;
  background-color: #f8f8f8;
}

.H-sec12-one-d1 span {
  background-color: #efefef;
  font-size: 0.8vw;
  font-family: "Space Grotesk";
  padding: 0.5% 1%;
  border-radius: 5px;
  font-weight: 500;
}

.H-sec12-one-d2 h1 {
  font-size: 3.5vw;
  color: #000000;
  font-weight: 400;
  margin: 1% 0 2% 0;
  font-family: "Barlow";
}

.H-sec12-one-d2 p {
  font-size: 1.1vw;
  margin-top: 2.5%;
  margin-bottom: 5%;
  font-family: "Barlow";
  color: #9c9c9c;
}

.h-sec12-btn {
  font-size: 0.9vw;
  padding: 1.3%;
  background-color: #f8f8f8;
  border: 1px solid rgb(205, 200, 200);
  border-radius: 5px;
  font-family: "Barlow";
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .H-sec12-one {
    padding: 15% 5% 5% 6%;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
  }

  .H-sec12-one-d1 span {
    background-color: #efefef;
    padding: 0.7% 1%;
    border-radius: 3px;
    font-size: 1.5vw;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .H-sec12-one-d2 h1 {
    font-size: 5.1vw;
    font-weight: 400;
    margin-bottom: 3%;
    margin-top: 6%;
    letter-spacing: -1px;
  }

  .H-sec12-one-d2 p {
    font-size: 2vw;
    margin-top: 5%;
    margin-bottom: 6%;
    opacity: 50%;
  }

  .h-sec12-btn {
    font-size: 1.8vw;
    padding: 2%;
    background-color: #f7f6f6;
    border: 2px solid rgb(227, 220, 220);
    border-radius: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec12-one {
    padding: 15% 5% 5% 5%;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
  }

  .H-sec12-one-d1 span {
    background-color: #efefef;
    padding: 1.5%;
    border-radius: 5px;
    font-size: 3vw;
    font-weight: 500;
  }

  .H-sec12-one-d2 h1 {
    font-size: 12vw;
    font-weight: 400;
    margin-bottom: 10%;
    margin-top: 15%;
    font-family: "Barlow";
    letter-spacing: -1.1px;
  }

  .H-sec12-one-d2 p {
    font-size: 3.7vw;
    margin-top: 8%;
    margin-bottom: 15%;
    opacity: 50%;
  }

  .h-sec12-btn {
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0.7px;
    padding: 3%;
    background-color: #f8f8f8;
    border: 2px solid rgb(227, 220, 220);
    border-radius: 5px;
    margin-bottom: 10%;
  }
}
/* section 12 two */

.H-sec12-two {
  height: auto;
  width: 100%;
  display: grid;
  padding: 0% 1% 10% 1%;
  grid-template-columns: repeat(4, 1fr);
  background-color: #f8f8f8;
}

.H-sec12-two-d1 {
  height: auto;
  width: 24vw;
  background-color: #f8f8f8;
  padding: 3%;
  border-right: 1px solid rgb(230, 224, 224);
}

.h-sec12-img1 {
  width: 22.4vw;
}

.h-sec12-br {
  border: none;
}

.H-sec12-two-d1 h6 {
  font-size: 1.4vw;
  font-weight: 400;
  line-height: 27px;
  margin: 2.9% 0 10% 0;
  font-family: "Barlow";
  margin-right: 15%;
  margin-bottom: 10%;
}
#H-sec12-two-d1-h6 {
  margin-bottom: 19.5%;
}

#H-sec12-two-d1-h6-4 {
  margin-bottom: 19.5%;
}

.H-sec12-two-d1 p {
  font-size: 1vw;
  margin: 10% 0% 10% 0%;
}

.H-sec12-two-d1 span {
  margin-right: 1%;
  font-size: 1vw;
  font-family: "Space Grotesk";
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #161616;
}

.h-sec12-circle {
  margin-right: 1.5%;
  height: 1.18vw;
  width: 1.1vw;
  /* position: relative; */
}

.h-sec12-A {
  margin-left: 2%;

  /* position: relative; */
  top: -2px;
  width: 1.2vw;
  height: 1.9vh;
}

#h-sec12-lin {
  text-decoration: none;
  color: #0f46f5;
  padding-top: 4%;
}
#h-sec12-sp-p {
  padding-top: 8.2%;
  text-decoration: none;
  color: #0f46f5;
}
.h-sec12-link-L1 {
  padding-top: 2px;
}

@media only screen and (max-width: 768px) {
  .H-sec12-two {
    height: auto;
    width: 100%;
    display: grid;
    padding: 2% 5% 10% 6%;
    grid-template-columns: repeat(2, 1fr);
  }

  .H-sec12-two-d1 {
    height: auto;
    width: 44.5vw;
    /* background-color: rgb(252, 251, 249); */
    padding: 3% 3% 5% 3%;
    border-right: 1px solid rgb(222, 214, 214);
  }

  .h-sec12-img1 {
    width: 42vw;
  }

  .h-sec12-br {
    border: none;
  }

  .H-sec12-two-d1 h6 {
    font-size: 2.8vw;
    font-weight: 400;
    line-height: 26px;
    margin-top: 2%;
    margin-right: 7%;
  }

  .H-sec12-two-d1 p {
    font-size: 1.5vw;
    margin: 10% 0% 10% 0%;
  }

  .H-sec12-two-d1 span {
    margin-right: 0.5%;
    font-size: 1.8vw;
    font-weight: 600;
  }

  .h-sec12-circle {
    margin-right: 1%;
    height: 1.5vw;
    width: 1.5vw;
    position: relative;
    top: 0.3%;
  }

  .h-sec12-A {
    margin-left: 1%;
    margin-top: 1%;
    height: 1.6vw;
    width: 2vw;
  }
  #h-sec12-sp-p {
    padding-top: 8%;
    text-decoration: none;
  }

  #h-sec12-sp-s2 {
    padding-top: 8%;
  }
  .H-sec12-two-d1-d2 {
    border-right: none;
  }

  #h-sec12-lin {
    text-decoration: none;
    color: #161616;
  }
}

@media only screen and (max-width: 360px) {
  .H-sec12-two {
    height: auto;
    width: 100%;
    display: grid;
    padding: 2% 0% 10% 0%;

    grid-template-columns: 1fr;
  }

  .H-sec12-two-d1 {
    height: auto;
    width: 100%;
    /* background-color: rgb(252, 251, 249); */
    padding: 3% 3% 7% 3%;
    border-right: 1px solid white;
    border-bottom: 1px solid rgb(230, 220, 220);
  }

  .h-sec12-img1 {
    width: 100%;
  }

  .h-sec12-br {
    border: none;
  }

  .H-sec12-two-d1 h6 {
    font-size: 6vw;
    font-weight: 400;
    line-height: 26px;
    margin-top: 2%;
  }

  .H-sec12-two-d1 p {
    font-size: 3vw;
    margin: 10% 0% 10% 0%;
    opacity: 70%;
  }

  .H-sec12-two-d1 span {
    margin-right: 0.5%;
    font-size: 4vw;
    font-weight: 600;
  }

  .h-sec12-circle {
    margin-right: 0.8%;
    height: 3.5vw;
    width: 3.5vw;
    position: relative;
    top: 0.3%;
  }

  .h-sec12-A {
    margin-left: 1.5%;
    margin-top: 1%;
    height: 3.5vw;
    width: 4.5vw;
  }

  #h-sec12-lin {
    text-decoration: none;
    color: #161616;
  }
}

/* section 2 imgs */

.h-sec2-imgs {
  background-color: white;
  display: flex;
  column-gap: 2px;
  height: 10vh;
}
.image-scroll {
  display: flex;
  animation: scrollAnimation 12s linear infinite;
}
@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -100%
    ); /* Adjust the factor based on the number of images */
  }
}
