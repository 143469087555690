* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.B-sec1 {
  padding: 8% 5% 6% 9%;
  width: 100%;
}

.b-sec1-sp1 {
  background-color: #efefef;
  padding: 0.7% 0.8%;
  font-size: 0.75vw;
  font-weight: 400;
  margin-bottom: 2%;
  border-radius: 3px;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
  cursor: pointer;
}

.b-sec1-h1 {
  margin-top: 3%;
  font-size: 3.8vw;
  font-weight: 400;
  font-family: "Barlow";
  letter-spacing: -1.2px;
  padding-right: 13%;
  line-height: 65px;
}

.B-BAL {
  width: 1.2vw;
  height: 1.7vh;
  position: relative;
top: -1px;
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .B-sec1 {
    padding: 13% 5% 10% 6%;
  }

  .b-sec1-sp1 {
    background-color: #efefef;
    padding: 1%;
    font-size: 1.7vw;
    font-weight: 300;
    margin-bottom: 2%;
    border-radius: 3px;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
  }

  .b-sec1-h1 {
    margin-top: 7%;
    font-size: 6.5vw;
    font-weight: 400;
    font-family: "Barlow";
    letter-spacing: -1.2px;
    padding-right: 3%;
    line-height: 65px;
  }
  .B-BAL {
    width: 2.5vw;
    height: 2vh;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 360px) {
  .B-sec1 {
    padding: 20% 5% 10% 5%;
    width: 100%;
  }

  .b-sec1-sp1 {
    background-color: #efefef;
    padding: 1%;
    font-size: 4vw;
    font-weight: 500;
    margin-bottom: 2%;
    border-radius: 3px;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
  }

  .b-sec1-h1 {
    margin-top: 7%;
    font-size: 8.9vw;
    font-weight: 400;
    font-family: "Barlow";
    letter-spacing: -1.2px;
    line-height: 40px;
    padding-right: 0%;
  }

  .B-BAL {
    width: 4.5vw;
    height: 1.5vh;
    position: relative;
    top: 1px;
    margin-right: 5px;
  }
}

/* section 2 */

.B-sec2 {
  width: 100%;
  height: 80vh;
}

.B-exp1-img1 {
  width: 100%;
  height: 80vh;
}
.B-exp1-img1-mbv {
  display: none;
}

@media only screen and (max-width: 768px) {
  .B-sec2 {
    width: 100%;
    height: 65vh;
  }

  .B-exp1-img1 {
    width: 100%;
    height: 65vh;
  }
  .B-exp1-img1-mbv {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .B-sec2 {
    width: 100%;
    height: 33vh;
  }

  .B-exp1-img1 {
    width: 100%;
    height: 40vh;
    display: none;
  }

  .B-exp1-img1-mbv {
    display: block;
    width: 100%;
    height: 33vh;
  }
}

/* section 3 */

.B-sec3 {
  padding: 7% 28% 7% 17%;
  width: 100%;
  height: auto;
  font-family: "Barlow";
  color: #161616;
  letter-spacing: -0.1px;
  line-height: 28px;
  font-size: 1.25vw;
}

.B-sec3-p1 {
  color: #161616;
  margin-bottom: 5%;
}

.B-sec3-p3 {
  margin-top: 1%;
  margin-bottom: 4%;
}

.B-sec3-p5 {
  margin-top: 1%;
  margin-bottom: 0%;
}

.B-sec3-p6 {
  padding-left: 2%;
}

.B-sec3-p7 {
  padding-left: 2%;
  margin-bottom: 5%;
}

.B-sec3-p8 {
  margin-bottom: 2%;
}

.B-sec3-p9 {
  margin-bottom: 1%;
}

.B-sec3-p10 {
  padding-left: 2%;
}

.B-sec3-p11 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.B-sec3-p12 {
  margin-bottom: 1%;
}

.B-sec3-p13 {
  padding-left: 2%;
}
.B-sec3-p14 {
  margin: 4% 0 1% 0;
}

.B-sec3-p15 {
  margin-bottom: 5%;
}

@media only screen and (max-width: 760px) {
  .B-sec3 {
    padding: 7% 4% 5% 6%;
    width: 100%;
    height: auto;
    font-family: "Barlow";
    color: #161616;
    opacity: 85%;
    letter-spacing: -0.1px;
    line-height: 32px;
    font-size: 2.8vw;
    font-weight: 400;
  }

  .B-sec3-p1 {
    color: #161616;
    margin-bottom: 5%;
  }

  .B-sec3-p3 {
    margin-top: 1%;
    margin-bottom: 4%;
  }

  .B-sec3-p5 {
    margin-top: 1%;
    margin-bottom: 0%;
  }

  .B-sec3-p6 {
    padding-left: 2%;
  }

  .B-sec3-p7 {
    padding-left: 2%;
    margin-bottom: 5%;
  }

  .B-sec3-p8 {
    margin-bottom: 2%;
  }

  .B-sec3-p9 {
    margin-bottom: 1%;
  }

  .B-sec3-p10 {
    padding-left: 2%;
  }

  .B-sec3-p11 {
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .B-sec3-p12 {
    margin-bottom: 1%;
  }

  .B-sec3-p13 {
    padding-left: 2%;
  }
  .B-sec3-p14 {
    margin: 4% 0 1% 0;
  }

  .B-sec3-p15 {
    margin-bottom: 5%;
  }
}

@media only screen and (max-width: 360px) {
  .B-sec3 {
    padding: 7% 5% 5% 5%;
    width: 100%;
    height: auto;
    font-family: "Barlow";
    color: #161616;
    letter-spacing: -0.1px;
    line-height: 28px;
    font-size: 4vw;
  }

  .B-sec3-p1 {
    color: #161616;
    margin-bottom: 10%;
    margin-top: 5%;
  }

  .B-sec3-p3 {
    margin-top: 2%;
    margin-bottom: 10%;
  }

  .B-sec3-p5 {
    margin-top: 2%;
    margin-bottom: 3%;
  }

  .B-sec3-p6 {
    padding-left: 2%;
  }

  .B-sec3-p7 {
    padding-left: 2%;
    margin-bottom: 5%;
  }

  .B-sec3-p8 {
    margin-bottom: 2%;
    margin-top: 13%;
  }

  .B-sec3-p9 {
    margin-bottom: 1%;
  }

  .B-sec3-p10 {
    padding-left: 2%;
  }

  .B-sec3-p11 {
    margin-top: 13%;
    margin-bottom: 2%;
  }

  .B-sec3-p12 {
    margin-bottom: 1%;
  }

  .B-sec3-p13 {
    padding-left: 2%;
  }
  .B-sec3-p14 {
    margin: 12% 0 3% 0;
  }

  .B-sec3-p15 {
    margin-bottom: 5%;
  }

  .B-sec3-p16 {
    margin-top: 15%;
    margin-bottom: 25%;
  }
}

/* section 4 */

.B-sec4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2% 2% 5% 2%;
  height: auto;
}

.B-sec4-d1 {
  padding: 1%;
  width: 23vw;
  border-right: 1px solid rgb(240, 236, 236);
  background-color: #ffffff;
}

.B-sec4-img1 {
  width: 21vw;
}

.B-sec4-d1 h6 {
  font-size: 1.4vw;
  font-family: "Barlow";
  font-weight: 400;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 7%;
}

.B-sec4-d1 p {
  font-size: 0.5vw;
  padding-bottom: 10%;
}

.exp-c {
  font-size: 2vw;
  height: 2.2vh;
  width: 1.1vw;
  padding-right: 2px;
}

.exp-BA {
  width: 1.4vw;
  height: 1.8vh;
  padding-left: 5px;
  position: relative;
  top: 0px;
}
.B-sec4-d1-d2 {
  border-right: none;
}
.B-sec4-s1-s2 {
  margin-top: 8%;
}

@media only screen and (max-width: 760px) {
  .B-sec4 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 2% 0% 15% 5%;
    height: auto;
  }

  .B-sec4-d1 {
    padding: 1.5%;
    width: 45vw;
    border-right: 1px solid rgb(221, 218, 218);
    background-color: #ffffff;
  }

  .B-sec4-img1 {
    width: 41.8vw;
  }

  .B-sec4-d1 h6 {
    font-size: 3vw;
    font-family: "Barlow";
    font-weight: 400;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 7%;
  }

  .B-sec4-d1 p {
    font-size: 1vw;
    padding-bottom: 10%;
  }

  .exp-c {
    font-size: 1.5vw;
    height: 1.7vh;
    padding-right: 2px;
  }

  .exp-BA {
    width: 2.7vw;
    height: 1.7vh;
    padding-left: 5px;
    position: relative;
    top: 1px;
  }
  .B-sec4-d1-d2 {
    border-right: none;
  }
  .B-sec4-s1-s2 {
    margin-top: 8%;
  }
}

@media only screen and (max-width: 360px) {
  .B-sec4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2% 6% 15% 6%;
    height: auto;
  }

  .B-sec4-d1 {
    padding: 1.5%;
    width: 100%;
    background-color: #ffffff;
    border-right: none;
  }

  .B-sec4-img1 {
    width: 100%;
  }

  .B-sec4-d1 h6 {
    font-size: 6.6vw;
    font-family: "Barlow";
    font-weight: 400;
    padding-right: 6%;
    padding-top: 3%;
    padding-bottom: 10%;
  }

  .B-sec4-d1 p {
    font-size: 2.5vw;
    padding-bottom: 10%;
  }

  .exp-c {
    font-size: 1.5vw;
    height: 1.7vh;
    padding-right: 2px;
  }

  .exp-BA {
    width: 5.7vw;
    height: 1.7vh;
    padding-left: 5px;
    position: relative;
    top: 1px;
  }
  .B-sec4-d1-d2 {
    border-right: none;
  }
  .B-sec4-s1-s2 {
    margin-top: 8%;
  }

  .B-sec4-s1 {
    padding-bottom: 15%;
  }
}
