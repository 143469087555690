* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.XS-sec1 {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  margin-bottom: 5%;
}

.XS-sec1-one-img {
  width: 38vw;
  height: 100vh;
  position: fixed;
  top: 9vh;
  bottom: 30%;
}
.tabvimg {
  display: none;
}
.XS-sec1-two {
  padding-left: 1%;
  margin-top: 15%;
  padding-right: 8%;
}

.XS-sec1-two-sp1 {
  background-color: #efefef;
  padding: 0.5%;
  font-size: 0.8vw;
  font-weight: 500;
  margin-bottom: 2%;
  border-radius: 3px;
  font-family: "Space Grotesk";
  letter-spacing: 0.3px;
  cursor: pointer;
}

.XS-sec1-two-h1 {
  margin-top: 2.5%;
  margin-bottom: 6.5%;
  font-size: 3.55vw;
  font-weight: 400;
  font-family: "Barlow";
  letter-spacing: -1.2px;
}

.XS-sec1-two-h1 span {
  color: #707070;
}

.XS-sec1-two-h1-2 {
  font-size: 2.3vw;
  font-weight: 400;
  margin-bottom: 3%;
}

.XS-sec1-two-p1 {
  font-size: 1.5vw;
  margin-bottom: 11%;
  padding-right: 14%;
  font-family: "Barlow";
  color: #707070;
  line-height: 28px;
}

.XS-sec1-two-h6 {
  font-size: 1.1vw;
  font-weight: 600;
  margin-bottom: 5%;
  font-family: "Space Grotesk";
}

.XS-sec1-two-sp2-p1 {
  background-color: #efefef;
  padding: 1% 2% 1% 1%;
  font-size: 0.8vw;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 1%;
  font-family: "Barlow";
  letter-spacing: -0.1px;
  font-weight: 500;
}

.XS-sec1-two-sp2-p1 img {
  margin-right: 5px;
  width: 0.75vw;
  height: 1.8vh;
}

.XS-sec1-two-h1-h2 {
  font-size: 2.6vw;
  font-weight: 400;
  margin-top: 5%;
  margin-bottom: 4%;
  font-family: "Barlow";
  letter-spacing: -1.2px;
}

.XS-sec1-two-h1-h2 span {
  color: #707070;
}

.XS-sec1-two-p-p1 {
  font-size: 1.65vw;
  padding-right: 18%;
  margin-bottom: 10%;
  font-family: "Barlow";
  letter-spacing: -0.1px;
  line-height: 28px;
  color: #707070;
}

.XS-sec1-two-btn1 {
  font-size: 0.8vw;
  color: white;
  background-color: black;
  border: none;
  padding: 1.5%;
  font-family: "Space Grotesk";
  border-radius: 2px;
  text-decoration: none;
}
.whiteA {
  height: 0.8vw;
  height: 0.8vw;
  position: relative;
  top: -1px;
  padding-left: 1px;
}

.XS-sec1-two-h6-2 {
  font-size: 1.05vw;
  margin-top: 8%;
  margin-bottom: 3%;
  font-family: "Space Grotesk";
  letter-spacing: -0.2px;
  font-weight: 600;
}

.XS-sec1-two-sp-ps span {
  font-size: 2.8vw;
  margin-right: 2%;
  font-family: "Barlow";
  padding-top: 1px;
}

.XS-sec1-two-sp-ps {
  font-size: 1.7vw;
  padding-bottom: 0%;
  padding-top: 2%;
  font-family: "Barlow";
  font-weight: 400;
  cursor: pointer;
  /* border-bottom: 1.4px solid rgb(102, 98, 98); */
}

.XS-sec1-two-sp-pA {
  font-size: 1.4vw;
  margin-left: 5%;
  padding-bottom: 2%;
}

/* section 2 */

.XS-sec2 {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  padding: 3% 6% 6% 6%;
  background-color: whitesmoke;
  position: relative;
  /* overflow-x: hidden; */
}

.XS-sec2-in1 span {
  background-color: #efefef;
  padding: 1% 1.8%;
  border-radius: 3px;
  font-size: 0.8vw;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
}

.XS-sec2-in2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.XS-sec2-in2-d1 h1 {
  font-size: 2.5vw;
  font-weight: 400;
  margin-top: 2%;
  margin-bottom: 5%;
  font-family: "Barlow";
}

.XS-sec2-in2-d2-img {
  width: 25vw;
  margin-left: 3%;
}

.XS-sec2-in2-d1-p1 {
  color: #707070;

  font-size: 1vw;
  font-family: "Barlow";
  line-height: 18px;
  margin-bottom: 5%;
  padding-right: 5%;
}

.XS-sec2-in2-d1-sp1 {
  font-size: 1.15vw;
  font-family: "Space Grotesk";
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.XS-sec2-in2-d1-CI {
  margin-right: 1%;
  height: 1vw;
  width: 1vw;
}

.XS-sec2-in2-d1-ba {
  margin-left: 2%;
  height: 0.8vw;
}

@media only screen and (max-width: 768px) {
  .XS-sec1 {
    height: auto;
    width: 100%;
    display: flex;
    margin-bottom: 15%;
    padding-top: 3%;
  }

  .XS-sec1-one-img {
    display: none;
  }
  .tabvimg {
    position: static;
    display: block;
    width: 100%;
    padding: 0%;
    height: 65vh;
  }
  .XS-sec1-two {
    padding-right: 0;
    margin-right: 0;
    padding-left: 0%;
  }

  .XS-sec1-two-sp1 {
    background-color: #efefef;
    padding: 0.5%;
    font-size: 1.5vw;
    font-weight: 500;
    margin-bottom: 2%;
    border-radius: 3px;
    margin-left: 5%;
    letter-spacing: 1px;
  }

  .XS-sec1-two-h1 {
    margin-top: 3%;
    margin-bottom: 5%;
    font-size: 6vw;
    font-weight: 400;
    margin-left: 5%;
  }

  .XS-sec1-two-h1-2 {
    font-size: 4vw;
    font-weight: 400;
    margin-bottom: 3%;
    margin-top: 5%;
    margin-left: 5%;
  }

  .XS-sec1-two-p1 {
    font-size: 2.8vw;
    margin-top: 7%;
    margin-bottom: 9%;
    line-height: 33px;
    opacity: 60%;
    margin-left: 5%;
  }

  .XS-sec1-two-h6 {
    font-size: 2.1vw;
    font-weight: 600;
    margin-bottom: 3%;
    margin-left: 5%;
  }

  .XS-sec1-two-sp2-p1 {
    background-color: #efefef;
    padding: 1% 1.5%;
    font-size: 2vw;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 1%;
    margin-left: 5%;
    font-family: "Space Grotesk";
  }

  .XS-sec1-two-sp2-p1 img {
    margin-right: 5px;
  }

  .XS-sec1-two-h1-h2 {
    font-size: 5.5vw;
    font-weight: 400;
    margin-top: 5%;
    margin-bottom: 3%;
    margin-left: 5%;
    letter-spacing: -1.2px;
  }

  .XS-sec1-two-p-p1 {
    font-size: 2.7vw;
    padding-right: 5%;
    line-height: 36px;
    margin-bottom: 5%;
    opacity: 70%;
    margin-left: 5%;
  }

  .XS-sec1-two-btn1 {
    font-size: 2vw;
    color: white;
    background-color: black;
    border: none;
    padding: 1.5% 2%;
    border-radius: 3px;
    margin-left: 5%;
    text-decoration: none;
  }
  .whiteA {
    height: 2vw;
    height: 2vw;
    position: relative;
    top: 2px;
    margin-left: 5px;
  }

  .XS-sec1-two-h6-2 {
    font-size: 2vw;
    margin-top: 8%;
    margin-bottom: 3%;
    margin-left: 5%;
  }

  .XS-sec1-two-sp-ps span {
    font-size: 4vw;
    margin-right: 2.5%;
    margin-left: 5%;
  }

  .XS-sec1-two-sp-ps {
    font-size: 2.6vw;
    padding-bottom: 3%;
    padding-top: 2%;
    margin-right: 4%;
  }

  .XS-sec1-two-sp-pA {
    font-size: 2.4vw;
    margin-left: 5%;
    margin-top: 5%;
    margin-right: 4%;
    padding-bottom: 4%;
  }
  hr {
    margin: 0 5%;
  }

  /* section 2 */

  .XS-sec2 {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    padding: 5% 3% 6% 6%;
    background-color: rgb(253, 253, 253);
  }

  .XS-sec2-in1 span {
    background-color: #efefef;
    padding: 1% 1.8%;
    border-radius: 3px;
    font-size: 1.8vw;
  }

  .XS-sec2-in2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
  }

  .XS-sec2-in2-d1 h1 {
    font-size: 4.2vw;
    font-weight: 400;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 2.5%;
  }

  .XS-sec2-in2-d2-img {
    margin-top: 1%;
    width: 47vw;
  }

  .XS-sec2-in2-d1-p1 {
    color: #9c9c9c;
    font-size: 2.5vw;
    margin-bottom: 5%;
    margin-left: 2.5%;
    line-height: 30px;
  }

  .XS-sec2-in2-d1-sp1 {
    font-size: 2.2vw;
    font-weight: 500;
  }

  .XS-sec2-in2-d1-CI {
    margin-right: 0.5%;
    height: 1.7vw;
    width: 1.7vw;
    margin-left: 2.5%;
  }

  .XS-sec2-in2-d1-ba {
    margin-left: 1%;
    height: 1.5vw;
  }
}

@media only screen and (max-width: 360px) {
  .XS-sec1 {
    height: auto;
    width: 100%;
    display: block;
    margin-bottom: 30%;
    padding-top: 10%;
  }

  .XS-sec1-one-img {
    display: none;
  }
  .tabvimg {
    position: static;
    display: block;
    width: 100%;
    padding: 0%;
    height: 40vh;
  }
  .XS-sec1-two {
    padding-right: 0;
    margin-right: 0;
  }

  .XS-sec1-two-sp1 {
    background-color: #efefef;
    padding: 1%;
    font-size: 3vw;
    font-weight: 500;
    margin-bottom: 2%;
    border-radius: 3px;
    margin-left: 4%;
    letter-spacing: 1px;
    font-family: "Space Grotesk";
  }

  .XS-sec1-two-h1 {
    margin-top: 7%;
    margin-bottom: 15%;
    font-size: 8.8vw;
    font-weight: 400;
    margin-left: 4%;
  }

  .XS-sec1-two-h1-2 {
    font-size: 4vw;
    font-weight: 400;
    margin-bottom: 3%;
    margin-top: 5%;
    margin-left: 4%;
  }

  .XS-sec1-two-p1 {
    font-size: 4.1vw;
    margin-top: 7%;
    margin-bottom: 15%;
    line-height: 25px;
    opacity: 60%;
    margin-left: 4%;
    padding-right: 5%;
  }

  .XS-sec1-two-h6 {
    font-size: 4.7vw;
    font-weight: 500;
    margin-bottom: 8%;
    margin-left: 4%;
    font-weight: 600;
  }

  .XS-sec1-two-sp2-p1 {
    background-color: #efefef;
    padding: 2% 2.5%;
    font-size: 4.2vw;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 2.5%;
    margin-left: 4%;
    font-family: "Space Grotesk";
  }

  .XS-sec1-two-sp2-p1 img {
    margin-right: 5px;
  }

  .XS-sec1-two-h1-h2 {
    font-size: 9vw;
    letter-spacing: -1.2px;
    font-weight: 400;
    margin-top: 5%;
    margin-bottom: 7%;
    margin-left: 4%;
  }

  .XS-sec1-two-p-p1 {
    font-size: 4vw;
    letter-spacing: -0.1px;
    padding-right: 7%;
    line-height: 26px;
    margin-bottom: 10%;
    opacity: 70%;
    margin-left: 5%;
  }

  .XS-sec1-two-btn1 {
    font-size: 3.2vw;
    color: white;
    background-color: black;
    border: none;
    padding: 2.8% 4%;
    border-radius: 3px;
    margin-left: 4%;
  }
  .whiteA {
    height: 3vw;
    height: 3vw;
    position: relative;
    top: 2px;
    margin-left: 4px;
  }

  .XS-sec1-two-h6-2 {
    font-size: 4.3vw;
    font-weight: 600;
    color: #161616;
    margin-top: 20%;
    margin-bottom: 7%;
    margin-left: 4%;
  }

  .XS-sec1-two-sp-ps span {
    font-size: 9vw;
    margin-right: 3.5%;
    margin-left: 4%;
  }

  .XS-sec1-two-sp-ps {
    font-size: 4.8vw;
    padding-bottom: 3%;
    padding-top: 2%;
    margin-right: 4%;
  }

  .XS-sec1-two-sp-pA {
    font-size: 2.4vw;
    margin-left: 5%;
    margin-top: 5%;
    margin-right: 4%;
    padding-bottom: 4%;
  }
  hr {
    margin: 3% 3%;
  }

  /* section 2 */

  .XS-sec2 {
    display: block;
    padding: 5% 7% 20% 2%;
    background-color: white;
  }

  .XS-sec2-in1 span {
    background-color: #efefef;
    padding: 1.5% 2.5%;
    border-radius: 3px;
    font-size: 3.2vw;
    margin-left: 3%;
  }

  .XS-sec2-in2 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
  }

  .XS-sec2-in2-d1 h1 {
    font-size: 6.6vw;
    font-weight: 500;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 2.5%;
  }

  .XS-sec2-in2-d2-img {
    margin-top: 20%;
    width: 100%;
  }

  .XS-sec2-in2-d1-p1 {
    color: rgb(13, 13, 14);
    opacity: 60%;
    font-size: 3.7vw;
    margin-bottom: 5%;
    margin-left: 2.5%;
    padding-right: 2%;
    line-height: 20px;
  }

  .XS-sec2-in2-d1-sp1 {
    font-size: 4.2vw;
    font-weight: 500;
  }

  .XS-sec2-in2-d1-CI {
    margin-right: 0.9%;
    height: 3vw;
    width: 3vw;
    margin-left: 2.5%;
  }

  .XS-sec2-in2-d1-ba {
    margin-left: 2%;
    height: 3vw;
  }
}
