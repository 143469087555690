@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.I-sec1 {
  background-image: url(/src/Simages/I-sec1-img1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  width: 100%;
  padding: 10% 0 0% 7%;
}

.I-sec1 span {
  background-color: #efefef;
  padding: 0.3%;
  border-radius: 2px;
  font-size: 0.6vw;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
}

.I-sec1 h1 {
  font-size: 4.3vw;
  color: white;
  letter-spacing: 1px;
  font-weight: 400;
  margin-top: 1%;
  font-family: "Barlow";
  letter-spacing: -1.2px;
}

@media only screen and (max-width: 768px) {
  .I-sec1 {
    background-image: url(/src/Simages/I-sec1-img1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    width: 100%;
    padding: 20% 0 0% 7%;
  }

  .I-sec1 span {
    background-color: #efefef;
    padding: 0.5%;
    font-size: 1.8vw;
    border-radius: 3px;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
  }

  .I-sec1 h1 {
    font-size: 10vh;
    font-family: "Barlow";
    margin-top: 3%;
    color: white;
    letter-spacing: -1.2px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec1 {
    background-image: url(/src/Simages/I-sec1-img1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    width: 100%;
    padding: 35% 0 0% 7%;
  }

  .I-sec1 span {
    background-color: #efefef;
    padding: 1% 1.5%;
    font-size: 3.4vw;
    border-radius: 4px;
    letter-spacing: 1px;
  }

  .I-sec1 h1 {
    font-family: "Barlow";
    font-size: 10vw;
    margin-top: 10%;
    color: white;
    letter-spacing: -1.2px;
    font-weight: 300;
  }
  .I-sec1 h1 br {
    display: none;
  }
}

/* section 2 */

.I-sec2 {
  height: auto;
  width: 100%;
  padding: 5% 0 0% 6%;
  display: grid;
  grid-template-columns: 1fr 0.2fr;
}

.I-sec2 h1 {
  font-size: 3.5vw;
  line-height: 50px;
  font-weight: 400;
  font-family: "Barlow";
}

.I-sec2 h1 span {
  color: #707070;
}

.I-sec2-two {
  align-items: end;
  margin-top: 25%;
}

.I-sec-two-img1 {
  height: 3vw;
  margin-right: 7%;
}

@media only screen and (max-width: 768px) {
  .I-sec2 {
    height: auto;
    width: 100%;
    padding: 8% 5% 3% 6%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .I-sec2 h1 {
    font-size: 5.5vw;
    line-height: 55px;
    font-weight: 400;
    letter-spacing: -1px;
  }

  .I-sec2-two {
    display: flex;
    justify-content: end;
    margin-top: 5%;
  }

  .I-sec-two-img1 {
    height: 8vw;
    margin: 2%;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec2 {
    height: auto;
    width: 100%;
    padding: 15% 3% 0% 4%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .I-sec2 h1 {
    font-size: 8.8vw;
    line-height: 45px;
    font-weight: 400;
    letter-spacing: -1px;
  }
  .I-sec2 h1 br {
    display: none;
  }

  .I-sec2-two {
    display: flex;
    justify-content: end;
    margin-top: 5%;
  }

  .I-sec-two-img1 {
    height: 10vw;
    margin: 2%;
  }
}

/* section ls */

.I-sec-Ls {
  padding: 7%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.i-sec-ls-one-sp1 {
  font-size: 2.2vw;
  margin-right: 6%;
  font-family: "Barlow";
}

.i-sec-ls-one-sp2 {
  font-family: "Barlow";
  font-size: 1.8vw;
  font-weight: 500;
}
.i-sec-ls-one hr {
  margin: 4% 0;
}

.i-sec-ls-one p {
  font-size: 1.3vw;
  padding-left: 16%;
  padding-right: 18%;
  margin-bottom: 7%;
  font-family: "Barlow";
  font-weight: 400;
  line-height: 30px;
}

.i-sec-ls-one-sp2-img {
  width: 30vw;
  padding-left: 16%;
}

.img2ins {
  margin-top: 4%;
}
.i-sec-ls-one-h3 {
  display: none;
}

@media only screen and (max-width: 760px) {
  .I-sec-Ls {
    padding: 7% 5% 7% 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .i-sec-ls-one-sp1 {
    font-size: 2.7vw;
    margin-right: 6%;
    font-family: "Barlow";
  }

  .i-sec-ls-one-sp2 {
    font-family: "Barlow";
    font-size: 2vw;
    font-weight: 500;
  }
  .i-sec-ls-one hr {
    margin: 5% 0;
  }

  .i-sec-ls-one p {
    font-size: 1.5vw;
    padding-left: 17%;
    padding-right: 18%;
    margin-bottom: 7%;
    font-family: "Barlow";
    font-weight: 400;
    line-height: 25px;
  }

  .i-sec-ls-one-sp2-img {
    width: 38vw;
    padding-left: 16%;
  }

  .img2ins {
    margin-top: 0%;
  }
  .i-sec-ls-one-h3 {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .i-sec-ls-one-mb-v {
    display: none;
  }

  .i-sec-ls-one-h3 {
    display: block;
    font-size: 7.5vw;
    font-weight: 400;
    font-family: "Barlow";
    letter-spacing: -0.7px;
    margin-bottom: 3%;
  }
  .i-sec-ls-one-sp1-mb-v {
    display: none;
  }

  .I-sec-Ls {
    padding: 0% 1% 5% 3%;
    display: grid;
    grid-template-columns: 1fr;
  }
  /* .i-sec-ls-one-sp1 {
    font-size: 2.2vw;
    margin-right: 6%;
  } */

  .i-sec-ls-one-sp2 {
    font-size: 7.2vw;
    font-weight: 500;
  }
  .i-sec-ls-one hr {
    margin: 6% 0;
  }

  .i-sec-ls-one p {
    font-size: 3.9vw;
    padding-left: 0%;
    padding-right: 1%;
    margin-bottom: 7%;
  }

  .i-sec-ls-one-sp2-img {
    width: 100%;
    padding: 2% 3% 20% 1%;
  }
}

/* section 3 */

.I-sec3 {
  padding: 7%;
  display: grid;
  grid-template-columns: 0.8fr 1.5fr;
  background-color: #161616;
  margin-top: 0%;
}

.I-sec3-one span {
  background-color: #efefef;
  padding: 0.6% 1%;
  border-radius: 3px;
  font-family: "Space Grotesk";
  font-size: 0.8vw;
}

.I-sec3-two h1 {
  font-size: 3.1vw;
  font-weight: 400;
  color: white;
  margin-bottom: 10%;
  font-family: "Barlow";
  letter-spacing: -1.2px;
}

.I-sec3-two h1 span {
  color: #707070;
}

.I-sec3-in1 {
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  background-color: #2a2a2a;
  width: 50vw;
  padding: 4% 0% 3% 4%;
  margin-bottom: 2.5%;
  border-radius: 5px;
}

.I-sec-two-s1 h4 {
  font-size: 1.5vw;
  font-weight: 500;
  margin-bottom: 3.5%;
  color: #ffffff;
  font-family: "Barlow";
}
.I-sec-two-s1 {
  color: #ffffff;
  font-size: 1vw;
}
.I-sec-two-sp1 {
  margin-right: 8%;
  color: white;
  font-size: 0.8vw;
}

.I-sec-two-sp1 span {
  color: #9c9c9c;
  font-weight: 400;
  padding-left: 0.2%;
  padding-right: 7%;
}

.i-sec3-span2 {
  font-size: 0.9vw;
  color: #9c9c9c;
}

.I-sec-two-br1 {
  display: none;
}

.I-sec-two-s2 {
  align-self: center;
  padding-top: 1%;
}

/* .I-sec-two-s2-spn1 {
  color: #efefef;
  font-size: 10vh;
  font-weight: 100;
} */

.I-sec3-btn span {
  padding: 0% 10px;
  color: rgb(152, 158, 162);
}

/* .I-sec-two-s2-sp-line {
  color: #efefef;
  font-size: 5vw;
} */

.I-sec3-btn {
  background-color: #0f46f5;
  color: white;
  border: none;
  padding: 5% 5% 5% 5%;
  border-radius: 3px;
  font-size: 0.9vw;
  font-family: "Space Grotesk";
}

.I-sec3-btn-A {
  padding-left: 1px;
  position: relative;
  top: 0px;
  width: 1.3vw;
}

@media only screen and (max-width: 768px) {
  .I-sec3 {
    padding: 7% 4% 7% 5%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #161616;
  }

  .I-sec3-one span {
    background-color: #efefef;
    padding: 0.5% 0.8%;
    border-radius: 3px;
    font-size: 1.5vw;
    letter-spacing: 1px;
  }

  .I-sec3-two h1 {
    font-size: 5.4vw;
    font-weight: 400;
    color: white;
    margin-bottom: 8%;
    margin-top: 6%;
    letter-spacing: -1.2px;
  }

  .I-sec3-in1 {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    background-color: #2a2a2a;
    width: 100%;
    padding: 5% 2% 6% 4%;
    margin-bottom: 3.5%;
    border-radius: 5px;
  }

  .I-sec-two-s1 h4 {
    font-size: 2.7vw;
    font-weight: 500;
    margin-bottom: 5%;
    color: white;
  }
  .I-sec-two-s1 {
    color: white;
    font-size: 2.2vw;
  }

  .I-sec-two-sp1 {
    margin-right: 8%;
    color: white;
    font-size: 1.6vw;
  }

  .I-sec-two-sp1 span {
    color: #9c9c9c;
    font-weight: 400;
    padding-left: 0.2%;
    padding-right: 7%;
  }

  .i-sec3-span2 {
    font-size: 1.8vw;
    color: #9c9c9c;
  }

  .I-sec-two-br1 {
    display: none;
  }

  .I-sec-two-s2 {
    align-self: center;
    padding-left: 25%;
    padding-top: 10%;
  }
  .I-sec3-btn span {
    padding: 0% 8px;
    color: rgb(152, 158, 162);
  }

  .I-sec3-btn {
    background-color: #0f46f5;
    color: white;
    border: none;
    padding: 6.5% 7%;
    border-radius: 3px;
    font-size: 1.8vw;
  }

  .I-sec3-btn-A {
    padding-left: 5px;
    position: relative;
    top: 3px;
    width: 3.2vw;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec3 {
    padding: 20% 4% 15% 5%;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #161616;
  }

  .I-sec3-one span {
    background-color: #efefef;
    padding: 0.5% 1.8%;
    border-radius: 3px;
    font-size: 3.7vw;
  }

  .I-sec3-two h1 {
    font-size: 9vw;
    font-weight: 400;
    color: white;
    margin-bottom: 15%;
    margin-top: 12%;
    letter-spacing: -1.2px;
  }

  .I-sec3-in1 {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    background-color: #2a2a2a;
    padding: 8% 0 10% 5%;
    margin-bottom: 6%;
    border-radius: 5px;
  }

  .I-sec-two-s1 h4 {
    font-size: 5.4vw;
    font-weight: 500;
    margin-bottom: 6.5%;
    color: white;
  }
  .I-sec-two-s1 {
    color: white;
    font-size: 7vw;
    line-height: 20px;
    padding-bottom: 2%;
  }
  .I-sec-two-sp1 {
    margin-right: 8%;
    color: white;
    font-size: 3.6vw;
  }

  .i-sec3-span2 {
    font-size: 3.6vw;
    color: #9c9c9c;
  }

  .I-sec-two-br1 {
    display: block;
  }

  .I-sec-two-s2 {
    align-self: center;
    padding-left: 0%;
    padding-top: 6%;
  }

  .I-sec3-btn {
    background-color: #0f46f5;
    color: white;
    border: none;
    padding: 3.5% 4% 3.5% 4%;
    border-radius: 3px;
    font-size: 3.6vw;
  }

  .I-sec3-btn span {
    padding: 0% 5px;
    color: rgb(152, 158, 162);
  }

  .I-sec3-btn-A {
    padding-left: 3px;
    position: relative;
    top: 1.5px;
    width: 5.5vw;
  }
}

/* section 4 */

.I-sec4 {
  width: 100%;
  height: auto;
  padding: 6% 7% 7% 6%;
  display: grid;
  grid-template-columns: 0.8fr 1.5fr;
  background-color: #000000;
  border: 15px solid #161616;
}
.i-sec4-one span {
  background-color: #efefef;
  padding: 0.9% 1%;
  letter-spacing: 1px;
  border-radius: 3px;
  font-size: 0.8vw;
  font-family: "Space Grotesk";
}

.i-sec4-two {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 9%;
  padding-bottom: 15%;
}

.i-sec4-two-h1 {
  grid-column: 1/3;
}

.i-sec4-two-h1 h1 {
  font-size: 3.4vw;
  font-weight: 500;
  color: white;
  margin-bottom: 4%;
  font-family: "Barlow";
}

.i-sec4-imgs {
  width: 2vw;
  padding-bottom: 3%;
}

.i-sec4-two-d1 h4 {
  border-top: 1px solid rgb(61, 59, 59);
  font-size: 1.5vw;
  font-weight: 500;
  padding-top: 4%;
  color: white;
  padding-top: 6%;
  margin-bottom: 4%;
  font-family: "Barlow";
}

.i-sec4-two-d1 p {
  font-size: 1vw;
  color: #9c9c9c;
  font-family: "Barlow";
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .I-sec4 {
    width: 100%;
    height: auto;
    padding: 10% 2% 0% 4%;
    display: block;
    grid-template-columns: 1fr;
    background-color: #000000;
    border: 25px solid #161616;
  }
  .i-sec4-one span {
    background-color: #efefef;
    padding: 0.7%;
    border-radius: 3px;
    font-size: 1.6vw;
  }

  .i-sec4-two {
    display: block;
    height: auto;
  }

  .i-sec4-two-h1 h1 {
    font-size: 5.3vw;
    font-weight: 500;
    color: white;
    margin: 8% 0 10% 0;
    letter-spacing: 1px;
  }

  .i-sec4-imgs {
    width: 6vw;
    padding-bottom: 3%;
    padding-left: 2%;
  }

  .i-sec4-two-d1 h4 {
    border-top: 1px solid rgb(61, 59, 59);
    font-size: 3.3vw;
    font-weight: 500;
    padding-top: 5%;
    color: white;
    margin-bottom: 6%;
    padding-left: 2%;
  }

  .i-sec4-two-d1 p {
    font-size: 2.6vw;
    padding-right: 5%;
    color: #9c9c9c;
    margin-bottom: 8%;
    padding-left: 2%;
    font-family: "Barlow";
    line-height: 28px;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec4 {
    width: 100%;
    height: auto;
    padding: 15% 4% 0% 0%;
    display: block;
    grid-template-columns: 1fr;
    background-color: #000000;
    border: 20px solid #161616;
  }
  .i-sec4-one span {
    background-color: #efefef;
    padding: 1%;
    letter-spacing: 0.5px;
    border-radius: 2px;
    font-size: 3.3vw;
    letter-spacing: 1px;
  }

  .i-sec4-two {
    display: block;
    height: auto;
  }

  .i-sec4-two-h1 h1 {
    font-size: 8vw;
    font-weight: 500;
    color: white;
    margin: 15% 0 20% 0;
    letter-spacing: 1px;
  }

  .i-sec4-imgs {
    width: 8vw;
    padding-bottom: 7%;
    padding-left: 1%;
  }

  .i-sec4-two-d1 h4 {
    border-top: 1px solid rgb(61, 59, 59);
    font-size: 6vw;
    font-weight: 500;
    padding-top: 10%;
    color: white;
    margin-bottom: 6%;
    padding-left: 2%;
  }

  .i-sec4-two-d1 p {
    font-size: 4.5vw;
    padding-right: 0%;
    line-height: 27px;
    color: white;
    opacity: 60%;
    margin-bottom: 8%;
    padding-left: 2%;
  }
}

/* section 5 */

.I-sec5 {
  width: 100%;
  height: auto;
  padding: 7% 0 2% 7%;
}

.I-sec5 span {
  background-color: #efefef;
  padding: 0.2%;
  border-radius: 3px;
  font-size: 0.6vw;
  font-weight: 400;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
}

.i-sec5-one {
  padding: 1% 5% 5% 7%;
  display: grid;
  height: auto;
  grid-template-columns: 0.5fr 1fr;
}

.i-sec5-one-d1-img {
  width: 25.5vw;
  height: 27vw;
}

.i-sec5-one-d2 h1 {
  font-size: 2.6vw;
  font-weight: 500;
  margin-top: 3%;
  margin-bottom: 4%;
  font-family: "Barlow";
}

.i-sec5-one-d2 p {
  font-family: "Barlow";
  font-size: 1.4vw;
  letter-spacing: 0.1px;
  line-height: 30px;
  opacity: 90%;
  padding-right: 33%;
  margin-bottom: 4%;
  color: #161616;
  opacity: 87%;
}

.i-sec5-one-d2 h6 {
  color: #0f46f5;
  font-size: 1.2vw;
  font-weight: 500;
  font-family: "Barlow";
  letter-spacing: -1px;
}

@media only screen and (max-width: 768px) {
  .I-sec5 {
    width: 100%;
    height: auto;
    padding: 10% 0% 0% 5%;
    background-color: #f8f8f8;
  }

  .I-sec5 span {
    background-color: #efefef;
    padding: 0.7% 1%;
    font-size: 1.1vw;
    font-weight: 500;
    border-radius: 3px;
    letter-spacing: 0.6px;
  }

  .i-sec5-one {
    padding: 5% 5% 2% 5%;
    display: grid;
    height: 18vh;
    grid-template-columns: 0.9fr 1fr;
    background-color: #f8f8f8;
  }

  .i-sec5-one-d1-img {
    width: 39vw;
    height: 45vw;
    background-color: white;
  }

  .i-sec5-one-d2 h1 {
    font-size: 4vw;
    font-weight: 500;
    margin-top: 1%;
    margin-bottom: 5%;
  }

  .i-sec5-one-d2 p {
    font-size: 2.5vw;
    letter-spacing: -0.06px;
    opacity: 90%;
    padding-right: 0%;
    margin-bottom: 6%;
    line-height: 26px;
  }

  .i-sec5-one-d2 h6 {
    color: #0f46f5;
    font-size: 2vw;
    font-weight: 500;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec5 {
    width: 100%;
    height: 15vh;
    padding: 15% 0 0% 4%;
  }

  .I-sec5 span {
    background-color: #efefef;
    padding: 1%;
    font-size: 2.2vw;
    font-weight: 500;
    border-radius: 3px;
  }

  .i-sec5-one {
    padding: 5% 4% 0% 4%;
    display: block;
    height: auto;
  }

  .i-sec5-one-d1-img {
    width: 100%;
    height: 50vh;
  }

  .i-sec5-one-d2 {
    height: 0vh;
  }

  .i-sec5-one-d2 h1 {
    font-size: 6.5vw;
    font-weight: 500;
    margin-top: 8%;
    margin-bottom: 6%;
  }

  .i-sec5-one-d2 p {
    font-size: 4.1vw;
    letter-spacing: -0.06px;
    line-height: 24.72px;
    color: #161616;
    opacity: 87%;
    padding-right: 0%;
    margin-bottom: 6%;
  }

  .i-sec5-one-d2 h6 {
    color: #0f46f5;
    font-size: 4vw;
    font-weight: 500;
    letter-spacing: -0.6px;
  }
}

/* SECTION 7 */

.I-sec7-sp {
  padding: 5% 0 2% 3%;
  background-color: #f8f8f8;
}

.I-sec7-sp span {
  background-color: #efefef;
  padding: 0.2%;
  font-size: 0.7vw;
  font-weight: 500;
  border-radius: 3px;
  font-family: "Space Grotesk";
  letter-spacing: 1px;
}

@media only screen and (max-width: 768px) {
  .I-sec7-sp {
    padding: 7% 0 2% 7%;
    background-color: #f8f8f8;
  }

  .I-sec7-sp span {
    background-color: #efefef;
    padding: 0.5% 1%;
    font-size: 1.5vw;
    border-radius: 3px;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: "Space Grotesk";
  }

  .H-sec12-two-d1-tbv {
    border-right: none;
  }
}

@media only screen and (max-width: 360px) {
  .I-sec7-sp {
    padding: 10% 0 5% 5%;
    background-color: #f8f8f8;
    letter-spacing: 1px;
  }

  .I-sec7-sp span {
    background-color: #efefef;
    padding: 0.9% 1.5%;
    font-size: 2.5vw;
    font-weight: 400;
    border-radius: 3px;
  }
}

/* section L2 */

@media only screen and (max-width: 360px) {
  #i-sec-l2-h1 {
    font-size: 8vw;
    line-height: 35px;
  }
}

/* section L */

@media only screen and (max-width: 360px) {
  .i-sec-l-p1 {
    font-size: 7vw;
    line-height: 20px;
  }
}

.custom-link {
  color: inherit; /* Use the inherited text color */
  text-decoration: none; /* Remove underline */
}
