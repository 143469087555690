.C-sec1 {
    background-image: url(/src/Simages/C-sec1-bg1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 78vh;
    width: 100%;
    margin-top: 4.4%;
    padding: 7%;
  }

  .C-sec1 span{
    color: black;
    border: 1px solid white;
    background-color: aliceblue;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 2.5px;
    font-family: "space Grotesk";
    border-radius: 2px;
  }

  .C-sec1 h1{
    color: white;
    padding-top: 2%;
    font-family: "barlow";
    letter-spacing: 1px;
    font-size: 3.8vw;
    font-weight: 400;
  }