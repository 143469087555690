#tbv-nvbr {
  display: none;
}
@media only screen and (max-width: 760px) {
  #tbv-nvbr {
    width: 100%;
    height: auto;
    padding: 7% 0% 13% 6.5%;
    display: block;
  }

  .tb-v-sec2 {
    margin-top: 1%;
    margin-bottom: 8%;
  }

  .tab-ul li {
    list-style: none;
    padding-top: 4.5%;
    font-size: 2.7vw;
    font-family: "Barlow";
    font-weight: 500;
  }

  .tab-ul li a {
    text-decoration: none;
    color: black;
  }

  .tbrand {
    font-size: 3.8vw;
    text-decoration: none;
    color: black;
    font-family: "Open Sans";
    margin-right: 0.8rem;
  }

  .c-logo-t {
    height: 2.7vw;
    width: 3vw;
  }

  .tb-sec2-p1 {
    margin-top: 2%;
    font-size: 1.7vw;
    color: #161616;
    opacity: 60%;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 22px;
    margin-bottom: 5%;
  }

  .tb-sec2-p2 {
    font-family: "Space Grotesk";
    font-weight: 600;
    font-size: 2.2vw;
    margin-bottom: 2%;
  }

  .tb-sec4 {
    display: flex;
    gap: 20px;
    font-size: 2.5vw;
  }
  .tb-sec4-d2 {
    font-weight: 500;
  }
}

@media only screen and (max-width: 360px) {
  #tbv-nvbr {
    width: 100%;
    height: auto;
    padding: 7% 0% 20% 6%;
    display: block;
  }

  .tb-v-sec2 {
    margin-top: 10%;
    margin-bottom: 20%;
  }

  .tab-ul li {
    list-style: none;
    padding-top: 10%;
    font-size: 5.5vw;
    font-family: "Barlow";
    font-weight: 500;
  }

  .tab-ul li a {
    text-decoration: none;
    color: black;
  }

  .tbrand {
    font-size: 7.8vw;
    text-decoration: none;
    color: black;
    font-family: "Open Sans";
    margin-right: 0.8rem;
    padding-top: 50px;
  }

  .c-logo-t {
    height: 2.5vh;
    width: 5.5vw;
    margin-right: 0.8rem;
  }

  .tb-sec2-p1 {
    margin-top: 5%;
    font-size: 3.8vw;
    color: #161616;
    opacity: 60%;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 15%;
  }

  .tb-sec2-p2 {
    font-family: "Space Grotesk";
    font-weight: 500;
    font-size: 5vw;
    margin-bottom: 6%;
  }

  .tb-sec4 {
    display: flex;
    gap: 20px;
    font-size: 4.5vw;
  }
  .tb-sec4-d2 {
    font-weight: 500;
  }
}
