.f-container {
  width: 100%;
  background-color: #000000;
  position: relative;
  overflow-x: hidden;
}

.f1-logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 10%;
}

.f-container h1 {
  color: #ffffff;
  display: inline-block;
  font-size: 2.5vw;
  font-weight: 500;
  margin-right: 7%;

  top: 5px;
  /* position: relative; */
}

.f-logo {
  width: 2vw;
}

.f-container p {
  color: #ffffff;
  opacity: 60%;
  line-height: 15px;
  font-size: 1vw;
  padding-bottom: 0%;
}

.f-container h4 {
  color: #ffffff;
  margin-top: 15px;
  font-size: 1.4vw;
  font-weight: 400;
  margin-bottom: 12%;
}

.f-sec1-h4 {
  font-size: 1vw;
}

.f1-cont {
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  justify-items: center;
  padding: 6% 8% 0% 1%;
  justify-content: start;
}

.f1-first {
  text-align: start;
  padding-right: 2%;
}

.f2-cont h5 {
  color: #707070;
  margin-top: 3%;
  margin-left: 2%;
  margin-bottom: 1%;
  font-size: 1vw;
  font-weight: 500;
  font-family: "Space Grotesk";
  letter-spacing: -0.2px;
}

.f2-cont p {
  color: #ffffff;
  opacity: 60%;
  margin-left: 8%;
  padding: 1.5% 0;
  font-size: 1.2vw;
  font-family: "Space Grotesk";
  padding-bottom: 2%;
}

.f1-second {
  padding-left: 1%;
}

#f2-p1 {
  color: #ffffff;
  opacity: 60%;
  margin-left: 8%;
  padding: 1.5% 0;
  font-size: 1vw;
  font-family: "Space Grotesk";
}

.custom-link {
  color: inherit;
  /* Use the inherited text color */
  text-decoration: none;
  /* Remove underline */
}

.f-c-d {
  display: flex;
  gap: 10px;
  align-items: center;
}

.f-c-d p {
  color: #ffffff;
  opacity: 60%;
  line-height: 23px;
  font-size: 1vw;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.f-c-d a {
  text-decoration: none;
}

.f1-right-one {
  padding-right: 110px;
}

#f-dash-a {
  color: #9a9393;
  position: relative;
  top: -15px;
}

#f-em-p {
  color: #ffffff;
  font-size: 1vw;
  font-weight: 400;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .f1-cont {
    padding: 50px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: start;
    grid-column-gap: 5px;
    grid-row-gap: 30px;
    margin-bottom: 10%;
  }

  .f1-first {
    grid-column: 1/3;
  }

  .f1-first h1 {
    font-size: 3.7vw;
    font-weight: 400;
    font-family: "Open Sans";
  }

  .f-logo {
    width: 3vw;
  }

  #f1-f-p1 {
    font-size: 1.7vw;
    line-height: 20px;
  }

  .f-container h4 {
    color: #ffffff;
    margin-top: 15px;
    font-size: 2.7vw;
    font-weight: 400;
    margin-bottom: 30px;
    font-family: "Barlow";
  }

  .f-container p {
    color: #ffffff;
    opacity: 60%;
    line-height: 22px;
    font-size: 2vw;
    padding-bottom: 10%;
    font-family: "Barlow";
  }

  .f2-cont h5 {
    color: #707070;
    margin-left: 3%;
    margin-bottom: 4%;
    font-size: 2vw;
    font-weight: 500;
    font-family: "Space Grotesk";
    letter-spacing: -0.2px;
  }

  .f2-cont p {
    color: #ffffff;
    opacity: 60%;
    margin-left: 5%;
    padding: 3% 0;
    font-family: "Space Grotesk";
  }

  .f1-fifth {
    grid-row: 2;
  }

  #f2-p1 {
    font-size: 2.2vw;
    padding-bottom: 5%;
  }
}

@media only screen and (max-width: 360px) {
  .f1-cont {
    padding: 8% 7%;
    justify-items: start;
    margin-bottom: 10%;
    display: block;
  }

  .f1-first h1 {
    font-size: 6.1vw;
    font-weight: 400;
    display: inline;
  }

  .f-logo {
    width: 5vw;
    padding-right: 1px;
    position: relative;
    left: -10px;
  }

  #f1-f-p1 {
    font-size: 3.3vw;
    line-height: 17px;
  }

  .f-container p {
    color: #ffffff;
    opacity: 75%;
    line-height: 32px;
    font-size: 2.2vw;
    display: none;
  }

  .mb-f {
    display: block;
  }

  .f-container h4 {
    color: #ffffff;
    margin-top: 15px;
    font-size: 5.2vw;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .f2-cont h5 {
    color: #707070;
    margin-left: 5%;
    margin-bottom: 4%;
    font-size: 4vw;
    font-weight: 500;
  }

  .f2-cont p {
    color: #ffffff;
    opacity: 70%;
    margin-left: 5%;
    padding: 3% 0;
  }

  .f1-first p {
    display: block;
    line-height: 18px;
    font-size: 4vw;
    margin-top: 2%;
  }

  #f2-p1 {
    display: block;
    font-size: 4vw;
    margin-top: 2%;
  }
}

/* footer imgs */

.f-sec2-imgs {
  width: 100%;
  height: auto;
  display: flex;
  column-gap: none;
  background-color: #000000;
}

.f-sec2-img-d {
  width: auto;
  flex-grow: 1;
  border: none;
}

/* .h-sec2-img-d1 {
  flex-basis: 150px;
  width: 100%;
} */

.h-i-sec2-imgs {
  padding: 2%;
  background-color: #171616;
}

@media only screen and (max-width: 768px) {
  .f-sec2-imgs {
    padding-bottom: 2%;
  }
}

@media only screen and (max-width: 360px) {}

/* scroll images css */

.h-sec2-imgs {
  display: flex;
  column-gap: 2px;
  height: 60px;
}

.image-scroll {
  display: flex;
  animation: scrollAnimation 12s linear infinite;
}

@keyframes scrollAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
    /* Adjust the factor based on the number of images */
  }
}