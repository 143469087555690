@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500&family=Inter:wght@300&family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");

.C-sec {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
}

.ct-img {
  height: 100vh;
  width: 100%;
}

.c-sec-2 {
  padding-top: 10%;
}

.c-sec-2-sp1 {
  background-color: #efefef;
  font-size: 0.9vw;
  font-family: "Space Grotesk";
  border-radius: 2px;
  padding: 0.7%;
  letter-spacing: 1px;
  margin-left: 4%;
  font-weight: 500;
}

.c-sec-2-h1 {
  font-family: "barlow";
  font-size: 3.3vw;
  letter-spacing: -1.2px;
  padding-top: 2%;
  font-weight: 400;
  margin-left: 4%;
}

.c-sec-2-h1 span {
  color: #9c9c9c;
}

.c-sec3 {
  height: auto;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-template-rows: 0.9fr 1fr;
  grid-template-areas:
    "c1 c2"
    "c3 c3";
}

.c-sec9 {
  height: auto;
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-template-rows: 0.6fr 1fr;
  grid-template-areas:
    "c1 c2"
    "c3 c3";
}
.c-sec3-1 {
  grid-area: c1;
}

.c-sec3-1-p1 {
  margin-left: 8.4%;
  font-size: 0.9vw;
  padding-top: 5%;
  padding-right: 12%;
  letter-spacing: -0.1px;
  color: #9c9c9c;
}

.c-sec3-2 {
  grid-area: c2;
  padding-top: 7%;
  padding-left: 12%;
}

.c-sec3-2-p2 {
  font-size: 0.8vw;
  line-height: 15px;
  font-weight: 400;
  font-family: "Space Grotesk";
}

.c-sec3-2-p3 {
  color: #535151;
  position: relative;
  top: -10%;
}

.c-sec3-2-h6 {
  font-size: 1vw;
}

.c-sec3-in2 {
  display: flex;
  column-gap: 10px;
  position: relative;
  top: -13%;
}

.c-sec3-in2-sp1 {
  font-size: 0.85vw;
}

.c-sec3-in2-sp2 {
  font-size: 0.9vw;
  font-weight: 500;
}

.c-sec3-form {
  background-color: #f8f8f8;
  width: 100%;
  grid-area: c3;
  padding: 0%;
  position: relative;
  top: -35%;
}

.c-sec3-form-h6 {
  margin-left: 4%;
  padding-top: 5%;
  padding-bottom: 2%;
  font-size: 1vw;
  font-family: "Space Grotesk";
  font-weight: 500;
}
.c-sec3-form-p1 {
  margin-left: 4%;
  display: inline-block;
  color: #9c9c9c;
  font-size: 1vw;
  padding: 1.3%;
  width: 25vw;
  border: 1px solid rgb(154, 142, 142);
  border-radius: 2px;
  margin-bottom: 4%;
}

.c-sec3-form-p2 {
  margin-left: 4%;
  display: inline-block;
  color: #9c9c9c;
  font-size: 1vw;
  padding: 1.3%;
  width: 25vw;
  border: 1px solid rgb(154, 142, 142);
  border-radius: 2px;
  margin-bottom: 4%;
  padding-bottom: 12%;
}

.c-sec3-button {
  display: block;
  margin-left: 4%;
  border: none;
  color: #f8f8f8;
  background-color: black;
  padding: 1.1% 2.3%;
  font-size: 0.9vw;
  border-radius: 2px;
  margin-bottom: 4%;
}
