@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.d-sec1 {
  padding: 7% 8% 3% 8%;
  width: 100%;
  height: auto;
}

.d-sec1-s1 {
  background-color: #efefef;
  padding: 0.3%;
  border-radius: 2px;
  letter-spacing: .7px;
  font-family: "Space Grotesk";
  font-size: 0.65vw;
}

.d-sec1-h1 {
  margin-top: 1%;
  font-weight: 400;
  letter-spacing: -1.2px;
  font-family: "Barlow";
  font-size: 4.25vw;
 
}

.d-sec1-sp2 {
  color: #707070;
}

@media only screen and (max-width: 768px) {
  .d-sec1 {
    padding: 8% 5% 10% 6%;
    width: 100%;
    height: auto;
  }
  .d-sec1-s1 {
    background-color: #efefef;
    padding: 0.3% 0.5%;
    border-radius: 3px;
    letter-spacing: 1px;
    font-family: "Space Grotesk";
    font-size: 1.3vw;
  }

  .d-sec1-h1 {
    margin-top: 2%;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-family: "Barlow";
    font-size: 5.2vw;
    line-height: 50px;
  }
}

@media only screen and (max-width: 360px) {
  .d-sec1 {
    padding: 30% 5% 10% 5%;
    width: 100%;
    height: auto;
  }
  .d-sec1-s1 {
    background-color: #efefef;
    padding: 1.5% 1.5%;
    border-radius: 3px;
    letter-spacing: 1px;
    font-family: "Space Grotesk";
    font-size: 3vw;
  }

  .d-sec1-h1 {
    margin-top: 10%;
    font-weight: 400;
    letter-spacing: -1.2px;
    font-family: "Barlow";
    font-size: 8vw;
    line-height: 35px;
  }

  .d-sec1-h1 br {
    display: none;
  }
}

/* section 2 */

/* section 2 */

/* .S-sec2 {
  height: auto;
  width: 100%;
  padding: 1.2% 2% 1% 1.2%;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
}

.S-sec2-d1 img {
  width: 34vw;
  height: 27vw;
}

.S-sec2-d2 {
  height: 27vw;
  padding-left: 3%;
  display: grid;
  grid-template-columns: 1fr 0.4fr;
}

.S-sec2-d2-in1 h1 {
  font-size: 2.5vw;
  font-weight: 400;
  margin-top: 3%;
  margin-bottom: 4%;
  font-family: "Barlow";
  letter-spacing: -1.2px;
}
.S-sec2-d2-in1 p {
  font-size: 1.1vw;
  padding-right: 10%;
  margin-bottom: 3%;
  font-family: "Barlow";
  line-height: 24px;
}

.S-sec2-d2-in1 h6 {
  font-size: 1.2vw;
  margin-bottom: 5%;
  font-weight: 500;
  font-family: "Space Grotesk";
}

.S-sec2-d2-in1-sp1 {
  background-color: #f2f2f2;
  font-family: "Space Grotesk";
  padding: 1.5%;
  font-size: 1.1vw;
  margin-right: 1%;
  font-weight: 400;
  border-radius: 3px;
  margin-bottom: 5%;
}
.S-sec2-d2-in1-sp1 img {
  margin-right: 1%;
  width: 0.8vw;
  height: 0.9vw;
}

.S-sec2-d2-in1-sp2 {
  background-color: #f2f2f2;
  padding: 1.5%;
  display: inline-block;
  font-size: 1.1vw;
  margin-top: 2.9%;
  border-radius: 3px;
  font-family: "Space Grotesk";
}

.S-sec2-d2-in1-sp2 img {
  margin-right: 5px;
  width: 0.8vw;
  height: 0.9vw;
}

.S-sec2-d2-in2 {
  display: grid;
  align-items: end;
  padding-bottom: 5%;
}

.S-sec2-d2-in2 button {
  display: inline-block;
}

.S-sec2-btn {
  display: block;
  font-size: 1.2vw;
  width: 11vw;
  padding: 5% 6%;
  color: aliceblue;
  background-color: black;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-family: "Space Grotesk";
}

.S-sec2-a {
  margin-left: 7%;
  position: relative;
  height: 1.1vw;
  padding-top: 3px;
} */

/* hover effect */

/* .S-sec2:hover {
  background-color: #161616;
}

.S-sec2:hover h1 {
  color: #ffffff;
}
.S-sec2:hover p {
  color: #9c9c9c;
}

.S-sec2:hover h6 {
  color: #ffffff;
}

.S-sec2:hover span {
  background-color: rgb(37, 42, 42);
  color: #ffffff;
}

.S-sec2:hover .S-sec2-btn {
  background-color: #0f46f5;
} */
/* 
@media only screen and (max-width: 768px) {
  .S-sec2 {
    height: auto;
    width: 100%;
    padding: 1.2% 4% 1% 1.2%;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
  }

  .S-sec2-d1 img {
    width: 38vw;
    height: 29vw;
  }

  .S-sec2-d2 {
    height: 30vw;
    padding-left: 3%;
  }

  .S-sec2-d2-in1 h1 {
    font-size: 3.5vw;
    font-weight: 400;
    margin-top: 0%;
    margin-bottom: 4%;
    letter-spacing: -0.96px;
  }
  .S-sec2-d2-in1 p {
    font-size: 1.5vw;
    padding-right: 1%;
    color: #9c9c9c;
    margin-bottom: 3%;
    line-height: 20px;
  }

  .S-sec2-d2-in1 h6 {
    display: none;
    font-size: 1.2vw;
    margin-bottom: 5%;
    font-weight: 500;
  }

  .S-sec2-d2-in1-sp1 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
    font-size: 1vw;
    margin-right: 1%;
    border-radius: 3px;
    margin-bottom: 5%;
  }
  .S-sec2-d2-in1-sp1 img {
    display: none;
    margin-right: 1%;
    width: 0.8vw;
    height: 0.9vw;
  }

  .S-sec2-d2-in1-sp2 {
    display: none;
    background-color: #f2f2f2;
    padding: 1.5%;
  
    font-size: 1vw;
    margin-top: 2.9%;
    border-radius: 3px;
  }

  .S-sec2-d2-in1-sp2 img {
    display: none;
    margin-right: 5px;
    width: 0.8vw;
    height: 0.9vw;
  }

  .S-sec2-d2-in2 button {
    display: inline-block;
  }

  .S-sec2-btn {
    display: block;
    font-size: 1.7vw;
    width: 15vw;
    padding: 8% 6%;
    color: aliceblue;
    background-color: black;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    margin-bottom: 10%;
  }

  .S-sec2-a {
    margin-left: 7%;
    height: 2vw;
    position: relative;
    top: 2px;
    padding-top: 3px;
  }
} */
