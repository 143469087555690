

  .B-sec2-btn{
    display: inline-block;
    font-size: 0.9vw;
    padding: 1.5% 3%;
    color: #ffffff;
    background-color: black;
    border: none;
    border-radius: 3px;
    text-decoration: none;
    font-family: "Space Grotesk";
    font-weight: 500;
    cursor: pointer;
  }

  .S-sec2:hover .B-sec2-btn {
    background-color: #0f46f5;
  }


  .b-h-sec-2 {
    height: auto;
    width: 100%;
    display: grid;
    padding: 0% 1% 5% 1%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap:50px;
    background-color: #f8f8f8;
  }
  .bg-in-sec1 {
    height: auto;
    width: 32.6vw;
    background-color: #f8f8f8;
    padding: 3%;
    border-right: 1px solid rgb(230, 224, 224);
  }
  
  .bg-sec2-img1 {
    width: 30.5vw;
    margin-bottom:3%;
  }

  .bg-cd-h1{
    font-size:2.2vw;
  
  }