@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&family=Poppins&family=Space+Grotesk&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* .active-link {
  font-weight: bolder;
  background-color: #f0f0f0;
} */

a.active {
  font-size: 3.5vw;
  background-color: #f0f0f0;
  border-radius: 5px;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0px;
  height: 9vh;
  border-bottom: 1px solid #f1f1f1;
  z-index: 9;
}

.n-left {
  margin-left: 2.5%;
}

.logo-container{
  display: inline-flex;
  align-items: center;
}

.brand {
  font-size: 2.2vw;
  margin-right: 0.7rem;
  /* line-height: 12.39px; */
  color: #101010;
  text-decoration: none;
  font-weight: 600;
  font-family: "Open Sans";
}

.c-logo {
  width: 1.8vw;
  margin-right: 0.8rem;
  margin-top: 0.3rem;
}

.H-sec-SL {
  color: rgb(200, 200, 213);
  font-size: 2vw;
  font-weight: 200;
  margin-right: 0.8rem;
  width: 0.1vw;
}

.i-usa {
  /* align-items: center; */
  width: 3.8vw;
  padding-bottom: 10px;
}

.nav-list ul {
  display: flex;
  justify-content: center;
  margin-right: 120px;
}

.nav-list ul li {
  list-style: none;
}

.nav-list ul li a {
  display: block;
  text-decoration: none;
  color: rgb(17, 18, 18);
  padding: 0.4rem;
  font-family: "Space Grotesk";
  margin: 0 0.2rem;
  font-weight: 500;
  font-size: 0.85vw;
  letter-spacing: -0.4px;
  /* padding-top: 30px; */
  margin-top: 20px;
}

.nav-list ul li a.active {
  font-weight: 600;
}

.n-right {
  display: grid;
  grid-template-columns: 0.6fr 1.3fr 0.1fr 0.9fr;
  grid-column-gap: 1px;
  color: #f1f1f1;
  background-color: #101010;
  height: 9vh;
  align-items: center;
  justify-content: start;
}

.n-right section {
  line-height: 10px;
  font-size: 0.85vw;
  font-weight: 500;
  font-family: "Space Grotesk";
  /* padding: 20px 5px 10px 5px; */
}

#n-right-one {
  padding-left: 17px;
}

.H-divder {
  color: #ffffff;
  opacity: 20%;
  font-size: 4vh;
  padding-bottom: 8px;
  padding-left: 2px;
}

.hd-sec1-btt {
  display: none;
}

.tabn-hbm {
  display: none;
}

.ctcn {
  text-decoration: none;
  background-color: #0f46f5;
  color: #f1f1f1;
  font-size: 1vw;
  /* height: 9vh; */
  padding: 18px 20px;
  cursor: pointer;
  font-weight: 500;
  font-family: "space Grotesk";
}

/* .ctcn:hover {
  border: 1px solid blue;
} */

@media only screen and (max-width: 1390px) {
  .n-right {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr 0.1fr 0.9fr;
    grid-column-gap: 2px;
    color: #f1f1f1;
    background-color: #101010;
    height: 10vh;
    align-items: center;
    justify-content: start;
  }

  .n-right section {
    line-height: 18px;
    font-size: 0.8vw;
    font-weight: 500;
    font-family: "Space Grotesk";
    line-height: 19.6px;
    padding: 5px;
  }

  #n-right-one {
    padding-left: 17px;
  }

  .H-divder {
    color: #ffffff;
    opacity: 20%;
    font-size: 4vh;
    padding-bottom: 8px;
    padding-left: 2px;
  }

  .hd-sec1-btt {
    display: none;
  }

  .tabn-hbm {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  nav {
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0px;
    height: 10vh;
    border-bottom: 1px solid #f1f1f1;
  }

  .brand {
    font-size: 3.8vw;
    margin-right: 0.8rem;
    margin-left: 20px;
    color: #101010;
    text-decoration: none;
    font-weight: 600;
    font-family: "Open Sans";
    text-align: center;
    position: relative;
    top: 7px;
  }

  .c-logo {
    width: 3vw;
    margin-right: 0.8rem;
    position: relative;
    top: 7px;
  }

  .H-sec-SL {
    display: none;
    color: rgb(200, 200, 213);
    font-size: 2.6vw;
    font-weight: 200;
    margin-right: 1rem;
    width: 0.1vw;
  }

  .i-usa {
    display: none;
    align-items: center;
    height: 2vw;
  }

  .nav-list ul {
    display: none;
  }

  .n-right {
    display: none;
  }

  .hd-sec1-bt {
    display: block;
  }

  .tabn-hbm {
    display: block;
    position: absolute;
    right: 6%;
    top: 39%;
    height: 2.7vh;
  }
}

@media only screen and (max-width: 360px) {
  .brand {
    font-size: 6.8vw;
    margin-right: 0.6rem;
    margin-left: 10px;
    line-height: 12.39px;
    color: #101010;
    text-decoration: none;
    font-weight: 600;
    font-family: "Open Sans";
    text-align: center;
  }

  .c-logo {
    align-items: center;
    position: relative;
    top: 10px;
    width: 5.7vw;
    margin-right: 0.8rem;
  }

  .hd-sec1-bt {
    display: block;
  }

  .tabn-hbm {
    display: block;
    position: absolute;
    right: 6%;
    top: 42%;
    height: 2.2vh;
    width: 6.5vw;
  }
}